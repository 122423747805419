import { gql } from "@apollo/client";

const GET_MILESTONE_TYPE_BY_ID = gql`
  query MyQuery($input: GetMilestoneTypeInput!) {
    GetMilestoneType(input: $input) {
      id
      name
      image_file_location
    }
  }
`;

export default GET_MILESTONE_TYPE_BY_ID;

// {
//   "input": {
//     "id": 1
//   }
// }
