import { ApolloResponseWithChildrenInterface } from "./ApolloLazyDialogWrapper";
import ActivityIndicatorNoItemsComponent from "./ActivityIndicatorNoItemsComponent";
import ActivityAnimatedDialogLoading from "./lottie/ActivityAnimatedDialogLoading";
import ActivityAnimatedDialogError from "./lottie/ActivityAnimatedDialogError";


export default (
    {apolloActivityResponse,data, children}:ApolloResponseWithChildrenInterface
) => {
    console.log(data,'DATAA')
    const {
        loading,
        error,
        itemIsPresent
    } = apolloActivityResponse;
    return (
        loading ? <ActivityAnimatedDialogLoading/> :
            Boolean(error) || error !== undefined ? <ActivityAnimatedDialogError error={error} /> :
                itemIsPresent || data === undefined ? <ActivityIndicatorNoItemsComponent/> :
                    children
    );
}