import React, { useState, useEffect } from "react";
import "./ActionType.css";

interface PropValues {
  callToActionIdentifier?: string;
  primaryValues?: {
    video_url: string;
    embed_code: string;
    cta_type: "VIDEO";
  };
  onValueChange?: (name: string, value: any) => void;
}

interface VideoInterface {
  video_url: string;
  embed_code: string;
}

function ActionTypeVideo({ values, onValueChange }: any) {
  return (
    <div className="textContainer">
      <div className="darkBoxTitle">
        Video URL
        <div className="lightBoxTitle"></div>
      </div>
      <input
        className="editableSmallInputBoxCTA"
        type="text"
        value={values?.video_url || ""}
        onChange={(e) => onValueChange("video_url", e.target.value)}
        placeholder={"Enter the video URL here"}
      />
      <div className="darkBoxTitle">
        Embed Video
        <div className="lightBoxTitle"></div>
      </div>
      <textarea
        className="editableLargeInputBoxCTA"
        value={values?.embed_code || ""}
        placeholder={"Enter the video embed code here"}
        onChange={(e) => onValueChange("embed_code", e.target.value)}
      />
    </div>
  );
}

export default ActionTypeVideo;
