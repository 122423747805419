import React, { useState } from "react";
import "../App.css";
import Navbar from "../components/Navbar";
import * as IoIcons from "react-icons/io";
import * as BsIcons from "react-icons/bs";
import * as HiIcons from "react-icons/hi";
import { Line } from "react-chartjs-2";
import { CSVLink } from "react-csv";

function Users() {
  const userData = {
    newUsers: 625,
    totalUsers: 2254,
    dailyScreentime: 5,
    screentimeDuration: 3,
    actions: 4,
    userRetention: 22
  };

  //pull from API
  const newUserWeek = [5, 2, 7, 4, 6, 1, 1];
  const newUserWeekAvg = [2, 3, 8, 3, 7, 2, 2];
  const activeUsersWeek = [25, 30, 32, 29, 36, 41, 31];
  const activeUsersWeekAvg = [20, 31, 32, 39, 37, 42, 32];
  const dailyScreenTimeWeek = [25, 23, 22, 19, 16, 11, 11];
  const dailyScreenTimeWeekAvg = [12, 11, 13, 13, 13, 14, 12];
  const sessionScreenTimeWeek = [5, 3, 2, 9, 6, 1, 1];
  const sessionScreenTimeWeekAvg = [2, 1, 3, 3, 3, 4, 2];
  const actionWeek = [15, 13, 10, 29, 12, 13, 11];
  const actionWeekAvg = [12, 11, 13, 13, 13, 14, 12];
  const userRetentionWeek = [1, 4, 3, 3, 6, -1, -2];
  const userRetentionWeekAvg = [2, 1, 3, -2, 3, 2, 2];

  const newUserMonth = [
    5,
    2,
    7,
    4,
    6,
    1,
    1,
    5,
    2,
    7,
    4,
    6,
    1,
    1,
    5,
    2,
    7,
    4,
    6,
    1,
    1,
    5,
    2,
    7,
    4,
    6,
    1,
    1
  ];
  const newUserMonthAvg = [
    2,
    3,
    8,
    3,
    7,
    2,
    2,
    2,
    3,
    8,
    3,
    7,
    2,
    2,
    2,
    3,
    8,
    3,
    7,
    2,
    2,
    2,
    3,
    8,
    3,
    7,
    2,
    2
  ];
  const activeUsersMonth = [
    25,
    30,
    32,
    29,
    36,
    41,
    31,
    25,
    30,
    32,
    29,
    36,
    41,
    31,
    25,
    30,
    32,
    29,
    36,
    41,
    31,
    25,
    30,
    32,
    29,
    36,
    41,
    31
  ];
  const activeUsersMonthAvg = [
    20,
    31,
    32,
    39,
    37,
    42,
    32,
    20,
    31,
    32,
    39,
    37,
    42,
    32,
    20,
    31,
    32,
    39,
    37,
    42,
    32,
    20,
    31,
    32,
    39,
    37,
    42,
    32
  ];
  const dailyScreenTimeMonth = [
    25,
    23,
    22,
    19,
    16,
    11,
    11,
    25,
    23,
    22,
    19,
    16,
    11,
    11,
    25,
    23,
    22,
    19,
    16,
    11,
    11,
    25,
    23,
    22,
    19,
    16,
    11,
    11
  ];
  const dailyScreenTimeMonthAvg = [
    12,
    11,
    13,
    13,
    13,
    14,
    12,
    12,
    11,
    13,
    13,
    13,
    14,
    12,
    12,
    11,
    13,
    13,
    13,
    14,
    12,
    12,
    11,
    13,
    13,
    13,
    14,
    12
  ];
  const sessionScreenTimeMonth = [
    5,
    3,
    2,
    9,
    6,
    1,
    1,
    5,
    3,
    2,
    9,
    6,
    1,
    1,
    5,
    3,
    2,
    9,
    6,
    1,
    1,
    5,
    3,
    2,
    9,
    6,
    1,
    1
  ];
  const sessionScreenTimeMonthAvg = [
    2,
    1,
    3,
    3,
    3,
    4,
    2,
    2,
    1,
    3,
    3,
    3,
    4,
    2,
    2,
    1,
    3,
    3,
    3,
    4,
    2,
    2,
    1,
    3,
    3,
    3,
    4,
    2
  ];
  const actionMonth = [
    15,
    13,
    10,
    29,
    12,
    13,
    11,
    15,
    13,
    10,
    29,
    12,
    13,
    11,
    15,
    13,
    10,
    29,
    12,
    13,
    11,
    15,
    13,
    10,
    29,
    12,
    13,
    11
  ];
  const actionMonthAvg = [
    12,
    11,
    13,
    13,
    13,
    14,
    12,
    12,
    11,
    13,
    13,
    13,
    14,
    12,
    12,
    11,
    13,
    13,
    13,
    14,
    12,
    12,
    11,
    13,
    13,
    13,
    14,
    12
  ];
  const userRetentionMonth = [
    1,
    4,
    3,
    3,
    6,
    -1,
    -2,
    1,
    4,
    3,
    3,
    6,
    -1,
    -2,
    1,
    4,
    3,
    3,
    6,
    -1,
    -2,
    1,
    4,
    3,
    3,
    6,
    -1,
    -2
  ];
  const userRetentionMonthAvg = [
    2,
    1,
    3,
    -2,
    3,
    2,
    2,
    2,
    1,
    3,
    -2,
    3,
    2,
    2,
    2,
    1,
    3,
    -2,
    3,
    2,
    2,
    2,
    1,
    3,
    -2,
    3,
    2,
    2
  ];

  const WeekData = [
    newUserWeek,
    activeUsersWeek,
    dailyScreenTimeWeek,
    sessionScreenTimeWeek,
    actionWeek,
    userRetentionWeek
  ];
  const WeekDataAvg = [
    newUserWeekAvg,
    activeUsersWeekAvg,
    dailyScreenTimeWeekAvg,
    sessionScreenTimeWeekAvg,
    actionWeekAvg,
    userRetentionWeekAvg
  ];

  const MonthData = [
    newUserMonth,
    activeUsersMonth,
    dailyScreenTimeMonth,
    sessionScreenTimeMonth,
    actionMonth,
    userRetentionMonth
  ];
  const MonthDataAvg = [
    newUserMonthAvg,
    activeUsersMonthAvg,
    dailyScreenTimeMonthAvg,
    sessionScreenTimeMonthAvg,
    actionMonthAvg,
    userRetentionMonthAvg
  ];

  const GraphData = [WeekData, MonthData, WeekData, MonthData]; //fix last two sets of data
  const GraphDataAvg = [WeekDataAvg, MonthDataAvg, WeekDataAvg, MonthDataAvg]; //fix last two sets of data

  let newDate = new Date();

  const months = [
    "January",
    "Febuary",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ];

  let weekLabels = [];
  for (let i = 6; i >= 0; i--) {
    weekLabels.push(newDate.getMonth() + "/" + (newDate.getDate() + i));
  }

  let monthLabels = [];
  for (let i = 27; i >= 0; i--) {
    //hopefully there is a better way to do this so I am going to see if we can just pull it from the database
    monthLabels.push(newDate.getMonth() + "/" + (newDate.getDate() + i));
  }

  let sixMonthsLabels = [];
  for (let i = 5; i >= 0; i--) {
    let currentMonth = newDate.getMonth() - i;
    if (currentMonth < 0) {
      currentMonth = currentMonth + 12;
    }
    sixMonthsLabels.push(months[currentMonth]);
  }

  const labels = [weekLabels, monthLabels, sixMonthsLabels, sixMonthsLabels]; //update last one to all

  const [currentTab, setCurrentTab] = useState(0);
  const [curData, setCurData] = useState(GraphData[0][0]);
  const [curDataAvg, setCurDataAvg] = useState(GraphDataAvg[0][0]);
  const [curLabels, setCurLabels] = useState(labels[0]);
  const [curTime, setTime] = useState(0);

  const data = {
    labels: curLabels,
    datasets: [
      {
        label: "This Organization",
        data: curData,
        fill: false,
        backgroundColor: "rgb(54, 162, 235)",
        borderColor: "rgba(54, 162, 235, 0.7)"
      },
      {
        label: "Average Organization",
        data: curDataAvg,
        fill: false,
        backgroundColor: "rgb(54, 162, 235, 0.5)",
        borderColor: "rgba(54, 162, 235, 0.2)",
        borderDash: [10, 5]
      }
    ]
  };

  const options = {
    plugins: {
      legend: {
        display: false
      },
      myScale: {
        position: "right"
      }
    }
  };

  function avgDeviation(avg: number) {
    if (avg >= 0) {
      return (
        <div style={{ color: "green" }}>
          <BsIcons.BsArrowUp />
          {avg}%
        </div>
      );
    } else {
      return (
        <div style={{ color: "red" }}>
          <BsIcons.BsArrowDown />
          {-avg}%
        </div>
      );
    }
  }

  function differentGraph(index: number) {
    setCurrentTab(index);
    setCurData(GraphData[curTime][index]);
    setCurDataAvg(GraphDataAvg[curTime][index]);
  }

  function differentTimeframe(time: string) {
    var currentTime = 0;
    if (time === "0") {
      setTime(0);
      currentTime = 0;
    } else if (time === "1") {
      setTime(1);
      currentTime = 1;
    } else if (time === "2") {
      setTime(2);
      currentTime = 2;
    } else if (time === "3") {
      setTime(3);
      currentTime = 3;
    }
    setCurData(GraphData[currentTime][currentTab]);
    setCurDataAvg(GraphDataAvg[currentTime][currentTab]);
    setCurLabels(labels[currentTime]);
  }

  function topLine(index: number) {
    if (index === currentTab) {
      return "usersOptionBoxSelected";
    } else {
      return "usersOptionBox";
    }
  }

  return (
    <Navbar index={0}>
      <div className="App">
        <header className="App-header">
          <div className="pageHeader">
            <h1 className="pageTitle">
              <IoIcons.IoMdPerson className="groupIcon" />
              Users
            </h1>
          </div>
          <div className="userBox">
            <div className="usersOptionBoxs">
              <div className={topLine(0)} onClick={() => differentGraph(0)}>
                <div className="usersOptionTitle">New Users</div>
                <div className="usersOptionNumber">{userData.newUsers}</div>
                {avgDeviation(-22)}
              </div>
              <div className={topLine(1)} onClick={() => differentGraph(1)}>
                <div className="usersOptionTitle">Total Active Users </div>
                <div className="usersOptionNumber">{userData.totalUsers}</div>
                {avgDeviation(23)}
              </div>
              <div className={topLine(2)} onClick={() => differentGraph(2)}>
                <div className="usersOptionTitle">Daily Screentime</div>
                <div className="usersOptionNumber">
                  {userData.dailyScreentime}
                </div>
                {avgDeviation(-4.7)}
              </div>
              <div className={topLine(3)} onClick={() => differentGraph(3)}>
                <div className="usersOptionTitle">Minutes per Session</div>
                <div className="usersOptionNumber">
                  {userData.screentimeDuration}
                </div>
                {avgDeviation(14.5)}
              </div>
              <div className={topLine(4)} onClick={() => differentGraph(4)}>
                <div className="usersOptionTitle">Actions</div>
                <div className="usersOptionNumber">{userData.actions}</div>
                {avgDeviation(-22)}
              </div>
              <div className={topLine(5)} onClick={() => differentGraph(5)}>
                <div className="usersOptionTitle">User Retention</div>
                <div className="usersOptionNumber">
                  {userData.userRetention}%
                </div>
                {avgDeviation(-22)}
              </div>
              <CSVLink
                data={[curData]} //will need to update
                headers={curLabels}
                filename={"CivActionGraphData.csv"}
              >
                <HiIcons.HiDownload
                  className="downloadButtonGraph" /*Onclick need to download data as csv*/
                />
              </CSVLink>
            </div>

            <Line data={data} options={options} />
            <div className="graphDropdownDiv">
              <select
                className="graphTimeDropDown"
                onChange={e => differentTimeframe(e.target.value)}
                value={curTime}
              >
                <option value="0">Last 7 days</option>
                <option value="1">Last 28 days</option>
                <option value="2">Last 6 months</option>
                <option value="3">All</option>
              </select>
            </div>
          </div>
        </header>
      </div>
    </Navbar>
  );
}

export default Users;
