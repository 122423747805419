import { createTheme } from "@mui/material/styles";

const muiTheme = createTheme({

    palette: {
        primary: {
            main: "#F1EFEF",
        },
        // secondary: {
            // main: 'black',
        // },



    },
});

export default muiTheme;