import Navbar from "../components/Navbar";
import {Button, Card, CardContent, Container, Grid, Typography} from "@mui/material";
import useLogout from "../hooks/useLogout";
import {useContext} from "react";
import {UserClass} from "../models/class/UserClass";
import {UserContext} from "../context/UserContext";

export default () => {

    const {
        handleLogout
    } = useLogout();

    const user = useContext<UserClass>(UserContext);

    return (
        <Navbar index={3}>
            <Grid container style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh'}}>
                <Container maxWidth="lg">
                    <Card
                        raised
                        sx={{
                            padding: "0.1em",
                        }}
                    >
                        <CardContent>
                            <Grid container style={{flexDirection: "column"}}>
                                <Grid item>
                                    <Typography variant={"h3"} gutterBottom>Profile</Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant={"h5"} gutterBottom>{user.email}</Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant={"h5"} gutterBottom>{user.fullName}</Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant={"h5"} gutterBottom>{user.dateOfBirth}</Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant={"h5"} gutterBottom>{user.phoneNumber}</Typography>
                                </Grid>
                                <Grid item>
                                    <Button variant={"contained"} onClick={handleLogout}>Logout</Button>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>

                </Container>
            </Grid>
        </Navbar>
    )
}