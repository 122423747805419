import { gql } from "@apollo/client";

const ALL_MILESTONES = gql`
  query MyQuery {
    AllMilestones {
      id
      title
    }
  }
`;

export default ALL_MILESTONES;
