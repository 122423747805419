import {ApolloError, ApolloQueryResult, DocumentNode, useQuery} from "@apollo/client";
import { OperationVariables} from "@apollo/client/core";

export interface ApolloActivityResponse {
    loading: boolean;
    error: ApolloError | undefined;
    itemIsPresent: boolean;
    refetch: (variables?: Partial<any>) => Promise<any>;
}
interface GenericQueryResponse<ResponseObject> {
    data:ResponseObject;
    apolloActivityResponse: ApolloActivityResponse;
}
export interface GenericQueryIdVar {
    input:any,
}
const useValidationQuery = <ResponseObject extends unknown>(
    gqlQuery:DocumentNode,
    options: OperationVariables
):GenericQueryResponse<ResponseObject> => {

    const {
        data,
        loading,
        error,
        refetch
    } = useQuery<any,GenericQueryIdVar>(gqlQuery, {
        variables: {
            input: options,
        }
    });

    const checkIfDataUndefinedOrNull = typeof data === "undefined" || data === null;
    const validatedData = checkIfDataUndefinedOrNull ? data : destructureData(data);
    const itemIsPresent = checkIfDataUndefinedOrNull || Array.isArray(validatedData) && validatedData.length === 0;
    return {
        data: validatedData,
        apolloActivityResponse:{
            loading,
            error,
            itemIsPresent,
            refetch
        }
    }
};
export default useValidationQuery;

//the data is always a single object with a key.
//the key name depends on the query
//e.g. "QueryKeyName" : {...} -> which holds [] or {}
export const destructureData = (data:any) => {
    for(const queryKeyName in data)//for loop to destructure -> "QueryKeyName"
        return data[queryKeyName];//{...} -> which holds [] or {}
}















//dont delete this comment
