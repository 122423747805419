import React, { useState } from "react";
import SmallNavbar from "../components/SmallNavbar";
import * as BsIcons from "react-icons/bs";
import * as GiIcons from "react-icons/gi";
import { useMutation } from "@apollo/client";
import CREATE_MILESTONE_TYPE from "../GraphQL/Mutations/Create_Milestone_Type/create-milestone-type";

function IndividualMilestoneType() {
  const [milestoneType, setMilestoneType] = useState("New Milestone Type");
  const [readOnly, setReadOnly] = useState(true);
  const [showDropdown, setShowDropdown] = useState(false);
  const [emptyFieldError, setEmptyFieldError] = useState(false);
  const [milestoneTypeName, setMilestoneTypeName] = useState("");
  const [milestoneTypeIcon, setMilestoneTypeIcon] = useState("");
  const [addNewMilestoneType] = useMutation(CREATE_MILESTONE_TYPE);
  const [successMessage, setSuccessMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  console.log(milestoneTypeName, milestoneTypeIcon);

  const handleAddNewMilestoneType = (e: any) => {
    e.preventDefault();
    let fieldsToCheck = [milestoneTypeName, milestoneTypeIcon];

    // checks if any input values are empty
    const checkForEmptyFields = () => {
      const missingInput = fieldsToCheck.filter(
        (item: string | number) => !item && item !== 0
      );
      return missingInput.length ? true : false;
    };

    if (checkForEmptyFields()) {
      console.log("Please check that all fields are filled before submitting!");
      setEmptyFieldError(true);
    } else {
      addNewMilestoneType({
        variables: {
          input: {
            name: milestoneTypeName,
            image_file_location: milestoneTypeIcon,
          },
        },
      })
        .then(() => {
          setEmptyFieldError(false);
          setSuccessMessage(true);
          setMilestoneTypeName("");
          setMilestoneTypeIcon("");
        })
        .catch((e) => setErrorMessage(e.message));
    }
  };

  if (milestoneType === "New Milestone Type") {
    setMilestoneType("");
    setReadOnly(false);
  }

  function editableBox() {
    if (readOnly) {
      return "smallInputBox";
    } else {
      return "editableSmallInputBox";
    }
  }
  function title() {
    return "New Milestone Type";
  }

  return (
    <>
      <SmallNavbar type="MilestoneType" name={milestoneType} index={0}>
        <div className="App">
          <header className="App-header">
            <h1 className="pageTitle">
              <GiIcons.GiPhone
                className="groupIcon" /*change so it is milestone type icon selected by user*/
              />
              {title()}
              <BsIcons.BsPencilFill
                className="editButton"
                onClick={() => setReadOnly(!readOnly)}
              />
              <BsIcons.BsThreeDots
                className="dotdotdotButton"
                onClick={() => setShowDropdown(!showDropdown)}
              />
              <div
                className={
                  showDropdown ? "active-dotDotDotArrow" : "dotDotDotArrow"
                }
              >
                <BsIcons.BsFillTriangleFill />
              </div>
              <div
                className={
                  showDropdown
                    ? "active-dotDotDotDropdown"
                    : "dotDotDotDropdown"
                }
              >
                <button className="dropDownOption">
                  Archive Milestone Type
                </button>

                <button className="dropDownOption">Download Analytics</button>
              </div>
            </h1>
            <div className="darkBoxTitle">
              Name of Milestone Type
              <div className="lightBoxTitle">25 character limit</div>
            </div>
            <input
              className={editableBox()}
              readOnly={readOnly}
              type="text"
              maxLength={25}
              value={milestoneTypeName}
              placeholder={"Enter milestone type"}
              onChange={(e) => setMilestoneTypeName(e.target.value)}
            />
            <div className="darkBoxTitle">
              Milestone Type Icon
              {/* <div className="lightBoxTitle">.png or .avg --- 3mb limit</div> */}
            </div>
            {/* <input //need to update so it has an upload option and button
              className={editableBox()}
              readOnly={readOnly}
              type="file"
              value={milestoneTypeIcon}
              placeholder={"No file chosen"}
              onChange={(e) => setMilestoneTypeIcon(e.target.value)}
            /> */}
            <input
            className={editableBox()}
            readOnly={readOnly}
            type="text"
            value={milestoneTypeIcon}
            placeholder={"URL to icon"}
            onChange={(e) => setMilestoneTypeIcon(e.target.value)}
              />
            <div className="createNewButtonContainerMilestoneType">
              {emptyFieldError && !errorMessage && (
                <div className="errorMessageContainerSmall">
                  <h3>ERROR!</h3>
                  Please make sure all fields are filled out before submitting!
                </div>
              )}
              {errorMessage && (
                <div className="errorMessageContainerSmall">
                  <h3>ERROR!</h3>
                  {errorMessage}
                </div>
              )}
              {successMessage && (
                <div className="successMessageContainerSmall">
                  <h3>SUCCESS!</h3>
                  Milestone Type was created!
                </div>
              )}
              <button
                className="createNewButton"
                onClick={handleAddNewMilestoneType}
              >
                Create New Milestone Type
              </button>
            </div>
          </header>
        </div>
      </SmallNavbar>
    </>
  );
}

export default IndividualMilestoneType;
