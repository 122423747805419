import React from "react";
import {Route, Routes} from "react-router-dom";
import Milestones from "../pages/Milestones";
import IndividualMilestone from "../pages/IndividualMilestone";
import IndividualMilestoneAnalytics from "../pages/IndividualMilestoneAnalytics";

export default () => {
    return (
        <Routes>
            <Route index element={<Milestones/>} />
            <Route path="createUpdate" element={<IndividualMilestone/>}/>
            <Route path="analytics" element={<IndividualMilestoneAnalytics/>}/>
        </Routes>
    )
}