import {
    DocumentNode,
    LazyQueryHookOptions, QueryResult,
    useLazyQuery
} from "@apollo/client";
import {ApolloActivityResponse, destructureData} from "./useValidationQuery";

interface LazyValidationQueryResponse<ResponseObject> {
    lazyQuery: (options?: Partial<LazyQueryHookOptions<any, any>>) => Promise<QueryResult<any, any>>;
    data:ResponseObject;
    apolloActivityResponse: ApolloActivityResponse;
}
export interface GenericQueryIdVar {
    input:any,
}
const useLazyValidationQuery = <ResponseObject extends unknown>(
    gqlQuery:DocumentNode
):LazyValidationQueryResponse<ResponseObject>  => {

    const [lazyQuery,{
        data,
        loading,
        error,
        refetch
    }] = useLazyQuery<any,any>(gqlQuery);

    const checkIfDataUndefinedOrNull = typeof data === "undefined" || data === null;
    const validatedData = checkIfDataUndefinedOrNull ? data : destructureData(data);
    const itemIsPresent = checkIfDataUndefinedOrNull || Array.isArray(validatedData) && validatedData.length === 0;

    return {
        lazyQuery,
        data: validatedData,
        apolloActivityResponse:{
            loading,
            error,
            itemIsPresent,
            refetch
        }
    }
};
export default useLazyValidationQuery;















//dont delete this comment
