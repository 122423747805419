import React, {useState} from "react";

import {Link} from "react-router-dom";
import * as FaIcons from "react-icons/fa";
import * as FiIcons from "react-icons/fi";
import * as HiIcons from "react-icons/hi";
import * as BsIcons from "react-icons/bs";
import {CSVLink} from "react-csv";
import "rsuite-table/dist/css/rsuite-table.css";
import "../../App.css"
import Navbar from "../SmallNavbar";
import UnitedStatesDropDown from "../sort/UnitedStatesDropDown";
import SubscriberDropDown from "../sort/SubscriberDropDown";
import CountyDropDown from "../sort/CountyDropDown";
import TimeframeDropDown from "../sort/TimeframeDropDown";

function MilestoneTypes({children}: { children: JSX.Element }) {


    const dataList = [
        {
            rank: 1,
            type: "Watch",
            actionsTaken: 100,
            avg: 12,
            avgDeviation: 2.2,
            id: 1
        },
        {
            rank: 2,
            type: "Email",
            actionsTaken: 100,
            avg: 12,
            avgDeviation: 2.2,
            id: 2
        },
        {
            rank: 3,
            type: "Schedule",
            actionsTaken: 100,
            avg: 12,
            avgDeviation: -2.2,
            id: 3
        },
        {
            rank: 4,
            type: "Call",
            actionsTaken: 100,
            avg: 12,
            avgDeviation: 2.2,
            id: 4
        },
    ];

    const headers = [
        {label: "Rank", key: "rank"},
        {label: "Type", key: "type"},
        {label: "Actions Taken", key: "actionsTaken"},
        {label: "Avg for This Time Period", key: "avg"},
        {label: "Percent Deviation from Average", key: "avgDeviation"},
    ];


    function avgDeviation(avg: number) {
        if (avg >= 0) {
            return (
                <div style={{color: "green"}}>
                    <BsIcons.BsArrowUp/>
                    {avg}%
                </div>
            );
        } else {
            return (
                <div style={{color: "red"}}>
                    <BsIcons.BsArrowDown/>
                    {-avg}%
                </div>
            );
        }
    }

    const newMilestoneType = {
        pathname: "/milestone_types/createUpdate",
        name: "New Milestone Type",
    };

    return (
        <Navbar index={4}>
            <div className="App">
                <div className="pageHeader">
                    <h1 className="pageTitle">
                        <FiIcons.FiShield className="groupIcon"/>
                        Milestone Types
                        <Link className="createNewBox" to={newMilestoneType}>
                            <button className="createNew">Create Milestone Type</button>
                        </Link>
                    </h1>
                </div>
                {/*<div className="SelectionBoxLine">*/}
                {/*    <FaIcons.FaSort/>*/}
                {/*    <SubscriberDropDown/>*/}
                {/*    <UnitedStatesDropDown/>*/}
                {/*    <CountyDropDown/>*/}
                {/*    <TimeframeDropDown/>*/}
                {/*    <CSVLink*/}
                {/*        data={dataList}*/}
                {/*        headers={headers}*/}
                {/*        filename={"CivAction_Action_Types.csv"}*/}
                {/*    >*/}
                {/*        <HiIcons.HiDownload*/}
                {/*            className="downloadButton"*/}
                {/*        />*/}
                {/*    </CSVLink>*/}
                {/*</div>*/}
                {children}
            </div>
        </Navbar>
    );
}

export default MilestoneTypes;
