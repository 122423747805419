import React, { useState } from "react";
import { Link } from "react-router-dom";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import * as HiIcons from "react-icons/hi";
import * as BsIcons from "react-icons/bs";
import ReactTooltip from "react-tooltip";
import { CSVLink } from "react-csv";
import "rsuite-table/dist/css/rsuite-table.css";
import "../../App.css";
import { v4 as uuidv4 } from 'uuid';
import Navbar from "../SmallNavbar";
import SubscriberDropDown from "../sort/SubscriberDropDown";
import UnitedStatesDropDown from "../sort/UnitedStatesDropDown";
import CountyDropDown from "../sort/CountyDropDown";
import TimeframeDropDown from "../sort/TimeframeDropDown";

export default (
    {
        children,
    }:{
        children:JSX.Element,
    }) => {
    const [sortBy, setSortBy] = useState("subscribers");
    const [state, setState] = useState("allStates");
    const [county, setCounty] = useState("allCounties");
    const [timeFrame, setTimeframe] = useState("allTimeFrames");

    const dataList = [
        {
            rank: 1,
            name: "Milestone 1",
            actionsTaken: 100,
            avg: 12,
            id:1,
            avgDeviation: 2.2,
        },
        {
            rank: 2,
            name: "Milestone 2",
            actionsTaken: 100,
            avg: 12,
            id:2,
            avgDeviation: 2.2,
        },
        {
            rank: 3,
            name: "Milestone 3",
            actionsTaken: 100,
            avg: 12,
            id:3,
            avgDeviation: -2.2,
        },
        {
            rank: 4,
            name: "Milestone 4",
            actionsTaken: 100,
            avg: 12,
            id:4,
            avgDeviation: 2.2,
        },
    ];

    const headers = [
        { label: "Rank", key: "rank" },
        { label: "Name", key: "name" },
        { label: "Actions Taken", key: "actionsTaken" },
        { label: "Avg for This Time Period", key: "avg" },
        { label: "Percent Deviation from Average", key: "avgDeviation" },
    ];

    function chart() {
        return dataList.map((data, i) => row(data, i));
    }

    function row(data: any, i: number) {

        const path ="/milestones/" +data.id

        if (data.rank % 2) {
            return (
                <div className="tableDiv" key={uuidv4()}>
                    <Link className="row" to={path}>
                        <div className="evenBodyCellRank">{data.rank}</div>
                        <div className="evenBodyCell">{data.name}</div>
                        <div className="evenBodyCell">{data.actionsTaken}</div>
                        <div className="evenLargeBodyCell">{data.avg}</div>
                        <div className="evenLargeBodyCell">
                            {avgDeviation(data.avgDeviation)}
                        </div>
                    </Link>
                </div>
            );
        } else {
            return (
                <div className="tableDiv"key={uuidv4()}>
                    <Link className="row" to={path}>
                        <div className="oddBodyCellRank">{data.rank}</div>
                        <div className="oddBodyCell">{data.name}</div>
                        <div className="oddBodyCell">{data.actionsTaken}</div>
                        <div className="oddLargeBodyCell">{data.avg}</div>
                        <div className="oddLargeBodyCell">
                            {avgDeviation(data.avgDeviation)}
                        </div>
                    </Link>
                </div>
            );
        }
    }

    function avgDeviation(avg: number) {
        if (avg >= 0) {
            return (
                <div style={{ color: "green" }}>
                    <BsIcons.BsArrowUp />
                    {avg}%
                </div>
            );
        } else {
            return (
                <div style={{ color: "red" }}>
                    <BsIcons.BsArrowDown />
                    {-avg}%
                </div>
            );
        }
    }

    const newMilestone = {
        pathname: "/milestones/createUpdate",
        name: "New Milestone",
    };

    return (
        <Navbar index={1}>
            <div className="App">
                <div className="pageHeader">
                    <h1 className="pageTitle">
                        <FaIcons.FaFlag className="groupIcon" />
                        Milestones
                        <Link className="createNewBox" to={newMilestone}>
                            <button className="createNew">Create Milestone</button>
                            {/* <FaIcons.FaPlusCircle className="plusIcon" /> */}
                        </Link>
                    </h1>
                </div>
                {/*<div className="SelectionBoxLine">*/}
                {/*    <FaIcons.FaSort />*/}
                {/*    <SubscriberDropDown/>*/}
                {/*    <UnitedStatesDropDown/>*/}
                {/*    <CountyDropDown/>*/}
                {/*    <TimeframeDropDown/>*/}
                {/*    <CSVLink*/}
                {/*        data={dataList}*/}
                {/*        headers={headers}*/}
                {/*        filename={"CivAction_Milestones.csv"}*/}
                {/*    >*/}
                {/*        <HiIcons.HiDownload*/}
                {/*            className="downloadButton"*/}
                {/*        />*/}
                {/*    </CSVLink>*/}
                {/*</div>*/}
                {children}
            </div>
        </Navbar>
    );
}

