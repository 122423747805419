import React, {useContext, useState} from "react";
import "rsuite-table/dist/css/rsuite-table.css";
import "../App.css";
import MilestonePageWrapper from "../components/milestone/MilestonePageWrapper";
import useValidationQuery from "../hooks/useValidationQuery";
import {UserClass} from "../models/class/UserClass";
import {UserContext} from "../context/UserContext";
import ALL_MILESTONE_TYPES from "../GraphQL/Queries/All_Milestone_Types/all-milestone-types";
import {MilestoneTypeDTO} from "../models/dto/MilestoneType";
import MilestoneTypeMUITable from "../components/milestone_type/MilestoneTypeMUITable";
import ApolloWrapper from "../components/activity_indicator/ApolloWrapper";
import {LabelKeyObject} from "react-csv/components/CommonPropTypes";
import {HeaderClass} from "../models/class/HeaderClass";
import MilestoneTypePageWrapper from "../components/milestone_type/MilestoneTypePageWrapper";

function MilestoneTypes() {

    const user = useContext<UserClass>(UserContext);

    const headers = [
        {label: "Name", key: "name"},

    ] as LabelKeyObject[];
    const headerClass = new HeaderClass(headers);
    const {
        data: allMilestonesTypesDTO,
        apolloActivityResponse
    } = useValidationQuery<ReadonlyArray<any>>(ALL_MILESTONE_TYPES, {
        id: user.id
    });

    return (
        <MilestoneTypePageWrapper>
            <ApolloWrapper apolloActivityResponse={apolloActivityResponse}>
                <MilestoneTypeMUITable
                    allMilestonesTypesDTO={allMilestonesTypesDTO}
                    headerClass={headerClass}
                />
            </ApolloWrapper>
        </MilestoneTypePageWrapper>
    )
}

export default MilestoneTypes;
