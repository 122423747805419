import useAmplifyAuthentication from "./useAmplifyAuthentication";
import { useEffect } from "react";
import useLazyValidationQuery from "./useLazyValidationQuery";
import { UserDTO } from "../models/dto/UserDTO";
import GET_USER_PROFILE_DATA from "../GraphQL/Queries/get-user-profile-data";

export default () => {
  const managerEmail = useAmplifyAuthentication();

  const {
    lazyQuery,
    data: userData,
    apolloActivityResponse,
  } = useLazyValidationQuery<UserDTO>(GET_USER_PROFILE_DATA);

  useEffect(() => {
    (async () => {
      if (typeof managerEmail === "string") {
        await lazyQuery({
          variables: {},
        });
      }
    })();
  }, [managerEmail]);

  return {
    userData,
    apolloActivityResponse,
  };
};
