import { gql } from "@apollo/client";

const CREATE_MILESTONE_TYPE = gql`
  mutation CreateMilestoneType($input: CreateMilestoneTypeInput!) {
    createMilestoneType(input: $input) {
      name
    }
  }
`;

export default CREATE_MILESTONE_TYPE;

// {
//   "input": {
//     "name": STRING,
//     "image_file_location": STRING,
//   }
// }
