import * as MdIcons from "react-icons/md";
import {Link} from "react-router-dom";
import * as FaIcons from "react-icons/fa";
import React, {useState} from "react";
import Navbar from "../Navbar";
import {OrganizationDTO} from "../../models/dto/OrganizationDTO";
import {HeaderClass} from "../../models/class/HeaderClass";
import SubscriberDropDown from "../sort/SubscriberDropDown";
import UnitedStatesDropDown from "../sort/UnitedStatesDropDown";
import CountyDropDown from "../sort/CountyDropDown";
import TimeframeDropDown from "../sort/TimeframeDropDown";

export default (
    {
        children,
        headerClass,
        organizationsDTO
}:{
    children:JSX.Element,
        headerClass:HeaderClass,
        organizationsDTO: ReadonlyArray<OrganizationDTO>
}) => {
    const [sortBy, setSortBy] = useState("subscribers");
    const [state, setState] = useState("allStates");
    const [county, setCounty] = useState("allCounties");
    const [timeFrame, setTimeframe] = useState("allTimeFrames");

    return  <Navbar index={3}>
    <div className="App">
            <div className="pageHeader">
                <h1 className="pageTitle">
                    <MdIcons.MdGroups className="groupIcon" />
                    Organizations
                    <Link className="createNewBox" to={"/organizations/createUpdate"}>
                        <button className="createNew">Create Organization</button>
                        {/* <FaIcons.FaPlusCircle className="plusIcon" /> */}
                    </Link>
                </h1>
            </div>
            <div className="SelectionBoxLine">
                {/*<FaIcons.FaSort />*/}
                {/*<SubscriberDropDown/>*/}
                {/*<UnitedStatesDropDown/>*/}
                {/*<CountyDropDown/>*/}
                {/*<TimeframeDropDown/>*/}
                {/*<CSVLink*/}
                {/*    data={organizationsDTO}*/}
                {/*    headers={headerClass.labelKeyObjects}*/}
                {/*    filename={"CivAction_Organization.csv"}*/}
                {/*>*/}
                {/*    <HiIcons.HiDownload*/}
                {/*        className="downloadButton" */}
                {/*    />*/}
                {/*</CSVLink>*/}

            </div>
            {children}
        </div>
    </Navbar>

}
