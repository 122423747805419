import {DialogContent, Divider, Link, Typography} from "@mui/material";
import {useContext} from "react";
import {MilestoneTypeDTO} from "../../models/dto/MilestoneType";
import {ApolloLazyQueryContext} from "../query/ApolloLazyQueryDialogContextWrapper";

export default () => {
    const milestoneTypeDTO =  useContext<MilestoneTypeDTO>(ApolloLazyQueryContext);
    console.log(milestoneTypeDTO,'milestoneTypeDTOmilestoneTypeDTO')
    return (
        <DialogContent>
            <Typography variant={"h5"} style={{marginTop:"0.8em"}}> Milestone Type Name </Typography>
            <Divider/>
            <Typography style={{marginBottom:"1.3em"}}>
                {milestoneTypeDTO.name}
            </Typography>
        </DialogContent>
    )
}