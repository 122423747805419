import { ApolloResponseWithChildrenInterface } from "./ApolloWrapper";
import ActivityIndicatorNoItemsComponent from "./ActivityIndicatorNoItemsComponent";
import ActivityAnimatedLoading from "./lottie/ActivityAnimatedLoading";
import ActivityAnimatedError from "./lottie/ActivityAnimatedError";

export default (
    {apolloActivityResponse, children}:ApolloResponseWithChildrenInterface
) => {
    const {
        loading,
        error,
        itemIsPresent
    } = apolloActivityResponse;
    return (
        loading ? <ActivityAnimatedLoading/> :
            Boolean(error) || error !== undefined ? <ActivityAnimatedError error={error} /> :
                itemIsPresent ? <ActivityIndicatorNoItemsComponent/> :
                children
    );
}