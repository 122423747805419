import * as MdIcons from "react-icons/md";
import {Link} from "react-router-dom";
import * as FaIcons from "react-icons/fa";
import React, {useState} from "react";
import Navbar from "../Navbar";
import {HeaderClass} from "../../models/class/HeaderClass";

export default (
    {
        children,
        headerClass,
}:{
    children:JSX.Element,
        headerClass:HeaderClass,
}) => {
    const [sortBy, setSortBy] = useState("subscribers");
    const [state, setState] = useState("allStates");
    const [county, setCounty] = useState("allCounties");
    const [timeFrame, setTimeframe] = useState("allTimeFrames");

    return  <Navbar index={2}>
         <div className="App">
            <div className="pageHeader">
                <h1 className="pageTitle">
                    <MdIcons.MdGroups className="groupIcon" />
                    Goals
                    <Link className="createNewBox" to={"/goals/createUpdate"}>
                        <button className="createNew">Create Goal</button>
                    </Link>
                </h1>
            </div>
             {/*<div className="SelectionBoxLine">*/}
             {/*    <FaIcons.FaSort/>*/}
             {/*    <SubscriberDropDown/>*/}
             {/*    <UnitedStatesDropDown/>*/}
             {/*    <CountyDropDown/>*/}
             {/*    <TimeframeDropDown/>*/}
             {/*    <CSVLink*/}
             {/*        data={organizationDTO}*/}
             {/*        headers={headers}*/}
             {/*        filename={"CivAction_Action_Types.csv"}*/}
             {/*    >*/}
             {/*        <HiIcons.HiDownload*/}
             {/*            className="downloadButton"*/}
             {/*        />*/}
             {/*    </CSVLink>*/}
             {/*</div>*/}
            {children}
        </div>
    </Navbar>

}
