import "../../App.css"
import ActivityIndicatorComponent from "./ActivityIndicatorComponent";
import {ApolloActivityResponse} from "../../hooks/useValidationQuery";

export interface ApolloResponseWithChildrenInterface {
    apolloActivityResponse: ApolloActivityResponse;
    children: JSX.Element;
}
export default ({apolloActivityResponse, children}:ApolloResponseWithChildrenInterface) => {
    const {
        refetch,
    } = apolloActivityResponse;

    console.log("Apollo Response: ", apolloActivityResponse)

    return (
        <ActivityIndicatorComponent
            apolloActivityResponse={apolloActivityResponse}
        >
            {children}
        </ActivityIndicatorComponent>
    )
};