import { gql } from "@apollo/client";

const ALL_GOALS = gql`
  query MyQuery {
    AllGoals {
      id
      name
      statement
      icon_file_location
      share_text
    }
  }
`;
//    readonly id:string;
//     readonly statement:string;
//     readonly name:string;
//     readonly deadline:string;
//     readonly created_timestamp:string;
//     readonly updated_timestamp:string;
//     readonly manager?: ManagerDTO;
//     readonly icon_file_location: string;
//     readonly share_text:string;
export default ALL_GOALS;
