import React, {useContext, useState} from "react";
import "rsuite-table/dist/css/rsuite-table.css";
import "../App.css";
import MilestonePageWrapper from "../components/milestone/MilestonePageWrapper";
import MilestoneMUITable from "../components/milestone/MilestoneMUITable";
import {UserClass} from "../models/class/UserClass";
import {UserContext} from "../context/UserContext";
import {LabelKeyObject} from "react-csv/components/CommonPropTypes";
import {HeaderClass} from "../models/class/HeaderClass";
import useValidationQuery from "../hooks/useValidationQuery";
import ALL_ORGANIZATIONS from "../GraphQL/Queries/all-organizations";
import {MilestoneDTO} from "../models/dto/MilestoneDTO";
import ApolloWrapper from "../components/activity_indicator/ApolloWrapper";
import ALL_MILESTONES from "../GraphQL/Queries/all-milestones";


function Milestones() {

    const user = useContext<UserClass>(UserContext);

    const headers = [
        {label: "Title", key: "title"}
    ] as LabelKeyObject[];

    const headerClass = new HeaderClass(headers);

    const {
        data: milestoneDTOs,
        apolloActivityResponse
    } = useValidationQuery<ReadonlyArray<MilestoneDTO>>(ALL_MILESTONES, {
        id: user.id
    });

    return (

        <MilestonePageWrapper>
            <ApolloWrapper
                apolloActivityResponse={apolloActivityResponse}
            >
                <MilestoneMUITable
                    milestoneDTOs={milestoneDTOs}
                    headerClass={headerClass}
                />
            </ApolloWrapper>
        </MilestonePageWrapper>
    );
}

export default Milestones;
