import React, { useState, useEffect } from "react";
import SmallNavbar from "../components/SmallNavbar";
import ClearIcon from "@mui/icons-material/Clear";
import Checkbox from "@mui/material/Checkbox";
import MilestoneAutofill from "../components/MilestoneAutofill";
import MilestoneTypeAutofill from "../components/MilestoneTypeAutofill";
import CallToActionModal from "../components/CallToActionModal";
import * as FaIcons from "react-icons/fa";
import * as BsIcons from "react-icons/bs";
import Modal from "react-modal";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useMutation, useQuery } from "@apollo/client";
import ALL_GOALS from "../GraphQL/Queries/All_Goals/all-goals";
import GET_GOAL_MILESTONES_BY_NAME from "../GraphQL/Queries/Goal_Milestones_By_Name/goal-milestones-by-name";
import CREATE_MILESTONE from "./../GraphQL/Mutations/Create_Milestone/create-milestone";
import ALL_MILESTONE_TYPES from "./../GraphQL/Queries/All_Milestone_Types/all-milestone-types";
import ALL_CALL_TO_ACTION from "./../GraphQL/Queries/All_Call_To_Action/all-call-to-action";
import "../App.css";

import ActionTypeEmail from "../components/ActionTypes/ActionTypeEmail";
import ActionTypePhone from "../components/ActionTypes/ActionTypePhone";
import ActionTypeVideo from "../components/ActionTypes/ActionTypeVideo";
import ActionTypeVisitWebsite from "../components/ActionTypes/ActionTypeVisitWebsite";
import ActionTypePrepare from "../components/ActionTypes/ActionTypePrepare";
import ActionTypeSchedule from "../components/ActionTypes/ActionTypeSchedule";

interface Goal {
  id: number;
  key: number;
  name: string;
  column: string;
}

interface MappedGoal {
  index: number;
  id: number;
  key: number;
  name: string;
  column: string;
}

interface MilestoneType {
  id: number;
  name: string;
  key: number;
  item_file_location: string;
}

interface CallToAction {
  id: number;
  key: number;
  name: string;
}

interface GoalMilestones {
  id: number;
  key: number;
  name: string;
}

interface PrecedentMilestones {
  id: number;
  key: number;
}

interface EmailTypeInterface {
  emailTypeObject: object;
  columnIdentifier: string;
}

export type ActionTypesAvailable =
  | ""
  | "EMAIL"
  | "PHONE"
  | "VIDEO"
  | "VISIT_WEBSITE"
  | "PREPARE"
  | "SCHEDULE";

function IndividualMilestone(props: any) {
  const [milestoneName, setMilestoneName] = useState("New Milestone"); //useState(props.goalName or pull from database)
  const [icon, setIcon] = useState("");
  const [startDate, setStartDate] = useState("");
  const [startTime, setStartTime] = useState("");
  const [deadlineDate, setDeadlineDate] = useState("");
  const [deadlineTime, setDeadlineTime] = useState("");
  const [milestoneDescription, setMilestoneDescription] = useState("");
  const [readOnly, setReadOnly] = useState(true);
  const [showDropdown, setShowDropdown] = useState(false);
  const [actionName, setActionName] = useState("");
  const [totalActions, setTotalActions] = useState(1000);
  const [goal, setGoal] = useState("");
  const [mappedGoals, setMappedGoals] = useState<MappedGoal[]>([]);
  const [actionIcon, setActionIcon] = useState("");
  const [whyMatters, setWhyMatters] = useState("");
  const [takeAction, setTakeAction] = useState("");
  const [shareText, setShareText] = useState("");
  const [orgSearch, setOrgSearch] = useState("");
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [emptyFieldError, setEmptyFieldError] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [milestoneType, setMilestoneType] = useState<string>("Select");
  const [goals, setGoals] = useState<Goal[]>([]);
  const [goalId, setGoalId] = useState<number>();
  const [callToActionList, setCallToActionList] = useState<CallToAction[]>([]);
  const [primaryValues, setPrimaryValues] = useState<{ [k: string]: any }>();
  const label = {
    inputProps: { "aria-label": "Checkbox demo" },
  };
  const [userPrecedentMilestoneBoolean, setUserPrecedentMilestoneBoolean] =
    useState(false);

  const [secondaryValues, setSecondaryValues] = useState<{
    [k: string]: any;
  }>();
  const [tertiaryValues, setTertiaryValues] = useState<{
    [k: string]: any;
  }>();
  const [precedentMilestones, setPrecedentMilestones] = useState<
    PrecedentMilestones[]
  >([]);
  const [primaryCallToActionSelect, setPrimaryCallToActionSelect] = useState<
    ActionTypesAvailable | undefined
  >(undefined);
  const [secondaryCallToActionSelect, setSecondaryCallToActionSelect] =
    useState<ActionTypesAvailable | undefined>(undefined);
  const [tertiaryCallToActionSelect, setTertiaryCallToActionSelect] = useState<
    ActionTypesAvailable | undefined
  >(undefined);
  const [getGoalMilestonesByName, setGetGoalMilestonesByName] = useState<
    GoalMilestones[]
  >([]);
  const [addNewMilestone] = useMutation(CREATE_MILESTONE);

  useEffect(() => {
    setPrimaryValues({});
  }, [primaryCallToActionSelect]);

  useEffect(() => {
    setSecondaryValues({});
  }, [secondaryCallToActionSelect]);

  useEffect(() => {
    setTertiaryValues({});
  }, [tertiaryCallToActionSelect]);

  // LIST OF VALUES TO CHECK IF NOT FILLED IN
  const handleAddNewMilestone = (e: any) => {
    e.preventDefault();
    let fieldsToCheck = [
      milestoneName,
      milestoneDescription,
      goalId,
      deadlineDate,
      deadlineTime,
      totalActions,
      actionName,
      actionIcon,
      whyMatters,
      takeAction,
      primaryCallToActionSelect,
      primaryValues,
      startDate,
      startTime,
      shareText,
    ];
    // CHECKS IF ANY OF THE REQUIRED INPUT VALUES ARE NOT FILLED IN
    const checkForEmptyFields = () => {
      if (!primaryCallToActionSelect || milestoneType === "Select") return true;
      const missingInput = fieldsToCheck.filter(
        (item: any) => !item && item !== 0
      );
      return missingInput.length ? true : false;
    };

    // IF ANY REQUIRED INPUT FIELD IS EMPTY, RETURN ERROR MESSAGE
    if (checkForEmptyFields()) {
      console.log("Please check that all fields are filled before submitting!");
      setEmptyFieldError(true);
      // ELSE RUN THE CREATE MILESTONE MUTATION WITH GIVEN INPUTS
    } else {
      primaryValues!.cta_type = primaryCallToActionSelect;
      secondaryValues!.cta_type = secondaryCallToActionSelect || null;
      tertiaryValues!.cta_type = tertiaryCallToActionSelect || null;

      addNewMilestone({
        variables: {
          input: {
            title: milestoneName,
            description: milestoneDescription,
            goal: goalId,
            milestone_type: milestoneType,
            milestone_icon: icon,
            deadline: `${deadlineDate} ${deadlineTime}`,
            target_number_of_completions: totalActions,
            precedent_milestone: precedentMilestones,
            action_title: actionName,
            image_file_location: actionIcon,
            why_this_matters: whyMatters,
            primary_call_to_action_data: primaryValues,
            secondary_call_to_action_data: secondaryValues,
            tertiary_call_to_action_data: tertiaryValues,
            take_action: takeAction,
            action_start_timestamp: `${startDate} ${startTime}`,
            share: shareText,
            user_precedent_milestone: userPrecedentMilestoneBoolean,
          },
        },
      })
        .then((res) => {
          if (!res.errors) {
            console.log("Milestone successfully created!");
            setEmptyFieldError(false);
            setSuccessMessage(true);
            setMilestoneName("");
            setTotalActions(1000);
            setIcon("");
            setStartDate("");
            setStartTime("");
            setDeadlineDate("");
            setDeadlineTime("");
            setMilestoneDescription("");
            setActionName("");
            setGoal("");
            setActionIcon("");
            setWhyMatters("");
            setTakeAction("");
            setShareText("");
            setPrimaryValues({});
            setSecondaryValues({});
            setTertiaryValues({});
          }
        })
        .catch((e) => {
          // GraphQL errors can be extracted here
          if (e.graphQLErrors) {
            // reduce to get message
            setErrorMessage(e.message);
            console.log(e.graphQLErrors, e.message);
          }
          throw new Error(e.message);
        });
    }
  };

  // GET LIST OF ALL AVAILABLE GOALS FOR ASSOCIATED GOAL SELECT
  const { data: all_goals, loading: goalsLoading } = useQuery(ALL_GOALS);

  useEffect(() => {
    if (goals && !goalsLoading) {
      setGoals(all_goals?.AllGoals);

      const mapGoals = () => {
        return goals?.map((goal: any, index: number) => {
          let columnName;

          if (index === 0) {
            setGoal(goal.name);
            setGoalId(goal.id);
            columnName = "Goal 1";
          } else {
            columnName = "Available Goals";
          }

          return { ...goal, key: index + 1, column: columnName };
        });
      };
      setMappedGoals(mapGoals());
    }
  }, [all_goals?.AllGoals, goals, goalsLoading]);

  // GET LIST OF ALL THE CALL TO ACTIONS FOR CALL TO ACTION DROPDOWNS
  const { data: all_call_to_action, loading: callToActionLoading } =
    useQuery(ALL_CALL_TO_ACTION);

  useEffect(() => {
    if (all_call_to_action && !callToActionLoading)
      setCallToActionList(all_call_to_action?.AllCallToActions);
  }, [
    all_call_to_action?.AllCallToActions,
    callToActionList,
    callToActionLoading,
  ]);

  const handlePrecedentMilestone = (e: any, values: any) => {
    e.preventDefault();
    setPrecedentMilestones(() => {
      return values.map((item: any, key: number) => {
        return { id: item.id };
      });
    });
  };

  const handleMilestoneType = (e: any, values: any) => {
    e.preventDefault();
    setMilestoneType(values.id);
  };

  const onInputChange =
    (
      values: any,
      callToActionSelect: ActionTypesAvailable,
      callToActionIdentifier: string
    ) =>
    (name: string, value: string | number | string[]) => {
      handleAddCallToActionResult(
        { ...values, [name]: value },
        callToActionSelect,
        callToActionIdentifier
      );
    };

  function handleAddCallToActionResult(
    actionInfo: object,
    callToActionType: ActionTypesAvailable,
    columnId: string
  ): void {
    switch (columnId) {
      case "Primary":
        setPrimaryCallToActionSelect(callToActionType);
        setPrimaryValues(actionInfo);
        break;
      case "Secondary":
        setSecondaryCallToActionSelect(callToActionType);
        setSecondaryValues(actionInfo);
        break;
      case "Tertiary":
        setTertiaryCallToActionSelect(callToActionType);
        setTertiaryValues(actionInfo);
    }
  }

  const handleRemoveCallToAction = (callToActionIdentifier: string) => {
    switch (callToActionIdentifier) {
      case "Primary":
        if (secondaryCallToActionSelect) {
          setPrimaryCallToActionSelect(secondaryCallToActionSelect);
          setPrimaryValues(secondaryValues);
          if (tertiaryCallToActionSelect) {
            setSecondaryCallToActionSelect(tertiaryCallToActionSelect);
            setSecondaryValues(tertiaryValues);
            setTertiaryCallToActionSelect(undefined);
          } else {
            setSecondaryCallToActionSelect(undefined);
          }
        } else {
          setPrimaryCallToActionSelect(undefined);
        }
        break;
      case "Secondary":
        if (tertiaryCallToActionSelect) {
          setSecondaryCallToActionSelect(tertiaryCallToActionSelect);
          setSecondaryValues(tertiaryValues);
          setTertiaryCallToActionSelect(undefined);
        } else {
          setSecondaryCallToActionSelect(undefined);
        }
        break;
      case "Tertiary":
        setTertiaryCallToActionSelect(undefined);
    }
  };

  if (milestoneName === "New Milestone") {
    setMilestoneName("");
    setReadOnly(false);
  }

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: "rgb(58, 58, 58)",
      borderRadius: "10px",
      width: "50%",
    },
  };

  // HANDLES MAPPING OF LIST FOR DROPDOWN MENUS, RETURNS ITEM NAME
  const handleDropdown = (list: any) => {
    return list?.map((item: any, key: any) => (
      <option key={key}>{item.name}</option>
    ));
  };

  const marks = [
    {
      value: 0,
      label: "0 Miles",
    },
    {
      value: 50,
      label: "50 Miles",
    },
    {
      value: 100,
      label: "100 Miles",
    },
  ];

  const primaryOrg = { orgName: "Organization A" };

  const orgs = ["Organization B", "Organization C", "Organization D"];

  function secondaryOrgs(orgs: Array<string>) {
    return orgs.map((org, i) => (
      <div key={i} className="org">
        {org}
        <BsIcons.BsThreeDots /*need to find out functionality of button*/
          className="orgdotdotdotButton"
        />
      </div>
    ));
  }

  function editableSmallBox() {
    if (readOnly) {
      return "smallInputBox";
    } else {
      return "editableSmallInputBox";
    }
  }

  function editableTimeBox() {
    if (readOnly) {
      return "timeInputBox";
    } else {
      return "editableTimeInputBox";
    }
  }

  function editableLargeBox() {
    if (readOnly) {
      return "largeInputBox";
    } else {
      return "editableLargeInputBox";
    }
  }

  function title() {
    return "New Milestone";
  }
  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  const MovableItem = ({
    id,
    index,
    name,
    setMappedGoals,
    column,
  }: {
    id: number;
    index: number;
    name: string;
    setMappedGoals: any;
    column: string;
  }) => {
    const changeItemColumn = (currentItem: any, columnName: string) => {
      setMappedGoals((prevState: any) => {
        return prevState.map((e: any, i: any) => {
          if (e.column === "Goal 1") e.column = "Available Goals";
          return {
            ...e,
            column: e.name === name ? columnName : e.column,
            key: i,
          };
        });
      });
    };

    if (column === "Goal 1") {
      setGoal(name);
      setGoalId(id);
    }

    var className;
    if (column === "Available Goals") {
      className = "goalMovableItemOption";
    } else {
      className = "goalMovableItemTop";
    }

    const [{ isDragging }, drag] = useDrag({
      type: "Our first type",
      end: (item, monitor) => {
        const dropResult: any = monitor.getDropResult();
        if (dropResult && dropResult.name === "Available Goals") {
          changeItemColumn(item, "Available Goals");
          className = "goalMovableItemOption";
        } else {
          changeItemColumn(item, "Goal 1");
          className = "goalMovableItemTop";
        }
      },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    });

    const opacity = isDragging ? 0.4 : 1;

    return (
      <div ref={drag} className={className} style={{ opacity }}>
        {name}
      </div>
    );
  };

  const returnItemsForColumn: any = (columnName: any) => {
    return mappedGoals
      .filter((item) => item.column === columnName)
      .map((item, i) => (
        <MovableItem
          id={item.id}
          index={i}
          key={item.id}
          name={item.name}
          setMappedGoals={setMappedGoals}
          column={item.column}
        />
      ));
  };

  const Column = ({
    children,
    className,
    title,
  }: {
    children: any;
    className: string;
    title: string;
  }) => {
    const [, drop] = useDrop({
      accept: "Our first type",
      drop: () => ({
        name: title,
      }),
    });
    return (
      <div ref={drop} className={className}>
        {children}
      </div>
    );
  };
  const renderFormForActionType = (
    values: any,
    actionNumber: "Primary" | "Secondary" | "Tertiary",
    actionType: ActionTypesAvailable
  ) => {
    switch (actionType) {
      case "EMAIL":
        return (
          <ActionTypeEmail
            callToActionIdentifier={actionNumber}
            onValueChange={onInputChange(values, actionType, actionNumber)}
            values={values}
          />
        );
      case "PHONE":
        return (
          <ActionTypePhone
            callToActionIdentifier={actionNumber}
            onValueChange={onInputChange(values, actionType, actionNumber)}
            values={values}
          />
        );
      case "VIDEO":
        return (
          <ActionTypeVideo
            callToActionIdentifier={actionNumber}
            onValueChange={onInputChange(values, actionType, actionNumber)}
            values={values}
          />
        );
      case "VISIT_WEBSITE":
        return (
          <ActionTypeVisitWebsite
            callToActionIdentifier={actionNumber}
            onValueChange={onInputChange(values, actionType, actionNumber)}
            values={values}
          />
        );
      case "PREPARE":
        return (
          <ActionTypePrepare
            callToActionIdentifier={actionNumber}
            onValueChange={onInputChange(values, actionType, actionNumber)}
            values={values}
          />
        );
      case "SCHEDULE":
        return (
          <ActionTypeSchedule
            callToActionIdentifier={actionNumber}
            onValueChange={onInputChange(values, actionType, actionNumber)}
            values={values}
          />
        );
    }
  };

  return (
    <>
      <SmallNavbar type="Milestone" name={milestoneName} index={0}>
        <div className="App">
          <header className="App-header">
            <div className="affiliatedOrgBackground">
              <div className="affiliatedOrgTitle">Affiliated Organizations</div>
              <input
                type="text"
                value={orgSearch}
                placeholder={"Search..."}
                onChange={(e) => setOrgSearch(e.target.value)}
              />
              <button>SEARCH</button>
              <div className="affiliatedOrgSubTitle">Primary Org</div>
              <div className="org">{primaryOrg.orgName}</div>
              <div className="affiliatedOrgSubTitle">Affiliated Orgs</div>
              {secondaryOrgs(orgs)}
            </div>
            <h1 className="pageTitle">
              <FaIcons.FaFlag className="groupIcon" />
              {title()}
              <BsIcons.BsPencilFill
                className="editButton"
                onClick={() => setReadOnly(!readOnly)}
              />
              <BsIcons.BsThreeDots
                className="dotdotdotButton"
                onClick={() => setShowDropdown(!showDropdown)}
              />
              <div
                className={
                  showDropdown ? "active-dotDotDotArrow" : "dotDotDotArrow"
                }
              >
                <BsIcons.BsFillTriangleFill />
              </div>
              <div
                className={
                  showDropdown
                    ? "active-dotDotDotDropdown"
                    : "dotDotDotDropdown"
                }
              >
                <button className="dropDownOption">Archive Milestone</button>
                <button className="dropDownOption">Duplicate Milestone</button>
                <button className="dropDownOption">Mark as Completed</button>
                <button className="dropDownOption">Download Analytics</button>
              </div>
            </h1>
            {/* <div className="darkBoxTitle">
              {(actionsComplete / totalActions) * 100}% Complete,{" "}
              {(actionsCommitted / totalActions) * 100}% Committed
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <div
                className="progressBar"
                style={{
                  width: "800px",
                  height: "30px",
                  borderRadius: "20px",
                  display: "inline-flex",
                  borderColor: "gray",
                  borderWidth: "1px",
                  backgroundColor: "lightgray",
                  zIndex: 0,
                  marginTop: "5px",
                  overflow: "hidden",
                }}
              >
                <div
                  style={{
                    width:
                      (
                        ((actionsComplete + actionsCommitted) / totalActions) *
                        800
                      ).toString() + "px",
                    height: "30px",
                    backgroundColor: "grey",
                    position: "absolute",
                    zIndex: 1,
                    borderRadius: "20px",
                  }}
                >
                  <p />
                </div>
                <div
                  className="progressBarCompleted"
                  style={{
                    width:
                      ((actionsComplete / totalActions) * 800).toString() +
                      "px",
                    height: "30px",
                    backgroundColor: "#38ac74",
                    borderRadius: "20px",
                    zIndex: 2,
                    textAlign: "center",
                  }}
                >
                  <p />
                </div>
              </div>
              <div
                style={{
                  textAlign: "center",
                  marginLeft: "5px",
                }}
              >
                <div className="darkBoxTitle">Estimated Completion Date</div>
                <div>11/23/21</div>
              </div>
            </div> */}
            <div className="subTitleAttributes">Milestones Attributes</div>
            <div className="darkBoxTitle">Associated Goal *</div>
            <Modal
              isOpen={modalIsOpen}
              onRequestClose={closeModal}
              style={customStyles}
              ariaHideApp={false}
              contentLabel="Bug Modal"
            >
              <div className="modalTitle">Associated Goals</div>
              <div className="modalText">
                Here you can choose which Goal is connected to this Milestone by
                dragging and dropping.
              </div>
              <div className="goalColumnsDiv">
                <DndProvider backend={HTML5Backend}>
                  <div className="goalModalSections">
                    <div className="goalColumnTitle">Current Goal</div>
                    <Column title="Goal 1" className="goalsTopColumn">
                      {returnItemsForColumn("Goal 1")}
                    </Column>
                  </div>
                  <div className="goalModalSections">
                    <div className="goalColumnTitle">Available Goals</div>
                    <Column
                      title="Available Goals"
                      className="goalsOptionsColumn"
                    >
                      {returnItemsForColumn("Available Goals")}
                    </Column>
                  </div>
                </DndProvider>
              </div>
              <div className="sendOrSaveDiv">
                <button
                  onClick={closeModal}
                  className="sendButton" /*where is this sent to?*/
                >
                  Save & Publish
                </button>
                <button
                  onClick={closeModal}
                  className="saveButton" /*where is this saved to?*/
                >
                  Discard Changes
                </button>
              </div>
            </Modal>
            <div
              style={{ display: "flex", flexDirection: "row" }}
              onClick={openModal}
            >
              <div className="goalRowMilestonePage">
                <div className="goalName">{goal}</div>
              </div>
            </div>
            <div className="darkBoxTitle">
              Milestone Name *
              <div className="lightBoxTitle">45 character limit</div>
            </div>
            <input
              className={editableSmallBox()}
              readOnly={readOnly}
              type="text"
              maxLength={45}
              value={milestoneName}
              placeholder={"Enter milestone name"}
              onChange={(e) => setMilestoneName(e.target.value)}
            />
            <div className="darkBoxTitle">
              Target Number of Completions *
              <div className="lightBoxTitle">
                The number of actions required to complete the milestone
              </div>
              <div>
                {/* {actionsComplete}/ */}
                <input
                  className={editableTimeBox()}
                  value={totalActions}
                  readOnly={readOnly}
                  type="number"
                  onChange={(e) => setTotalActions(e.target.valueAsNumber)}
                />
              </div>
            </div>
            <div className="darkBoxTitle">
              Milestone Type *
              <div className="milestoneAutofillContainer">
                <MilestoneTypeAutofill
                  handleMilestoneType={handleMilestoneType}
                />
              </div>
            </div>

            <div className="darkBoxTitle">
              Milestone Icon *
              {/* <div className="lightBoxTitle">.png or .svg -- 3mb limit</div> */}
            </div>
            {/* <input
              className={editableSmallBox()}
              readOnly={readOnly}
              type="file"
              value={icon}
              placeholder={"No File Chosen"}
              onChange={(e) => setIcon(e.target.value)}
            /> */}
            <input
              className={editableSmallBox()}
              readOnly={readOnly}
              type="text"
              value={icon}
              placeholder={"Enter URL for Icon"}
              onChange={(e) => setIcon(e.target.value)}
            />
            <div className="darkBoxTitle">
              Start Date & Time *
              <div className="lightBoxTitle">
                The Action will be "Upcoming" until start date has passed and
                then it will be moved to the "Instant" tab.
              </div>
            </div>
            <input
              className={editableTimeBox()}
              readOnly={readOnly}
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
            <input
              className={editableTimeBox()}
              readOnly={readOnly}
              type="time"
              value={startTime}
              onChange={(e) => setStartTime(e.target.value)}
            />
            <div className="darkBoxTitle">
              Deadline *
              <div className="lightBoxTitle">
                24 hours after the deadline, the action will be archived and no
                longer shown in the feed.
              </div>
            </div>
            <input
              className={editableTimeBox()}
              readOnly={readOnly}
              type="date"
              value={deadlineDate}
              onChange={(e) => setDeadlineDate(e.target.value)}
            />
            <input
              className={editableTimeBox()}
              readOnly={readOnly}
              type="time"
              value={deadlineTime}
              onChange={(e) => setDeadlineTime(e.target.value)}
            />
            <div className="darkBoxTitle">
              Short Description Text *
              <div className="lightBoxTitle">200 Character limit</div>
            </div>
            <textarea
              className={editableLargeBox()}
              readOnly={readOnly}
              maxLength={200}
              value={milestoneDescription}
              placeholder={"Short description of what the milestone is"}
              onChange={(e) => setMilestoneDescription(e.target.value)}
            />
            <div className="darkBoxTitle">
              Prerequisite Milestones *
              <div className="lightBoxTitle">
                Action will not be shown in CivAction feed until all
                prerequisite milestones are completed by the community.
              </div>
            </div>
            <div className="milestoneAutofillContainer">
              <MilestoneAutofill
                goalId={goalId}
                handlePrecedentMilestone={handlePrecedentMilestone}
              />
            </div>
            <div className="subTitleAttributes">Action Attributes</div>
            <div className="darkBoxTitle">
              Action Name *
              <div className="lightBoxTitle">45 character limit</div>
            </div>
            <input
              className={editableSmallBox()}
              readOnly={readOnly}
              type="text"
              maxLength={45}
              value={actionName}
              placeholder={"Enter action name"}
              onChange={(e) => setActionName(e.target.value)}
            />
            <div className="darkBoxTitle">
              Action Image *
              {/* <div className="lightBoxTitle">.png or .svg -- 3mb limit</div> */}
            </div>
            {/* <input
              className={editableSmallBox()}
              readOnly={readOnly}
              type="file"
              value={actionIcon}
              placeholder={"No File Chosen"}
              onChange={(e) => setActionIcon(e.target.value)}
            /> */}
            <input
              className={editableSmallBox()}
              readOnly={readOnly}
              type="text"
              value={actionIcon}
              placeholder={"Enter URL for Image"}
              onChange={(e) => setActionIcon(e.target.value)}
            />
            <div className="callsToActionContainer">
              <div className="callToActionIndividualContainer">
                <div className="darkBoxTitle">
                  Primary Call To Action *
                  <div className="lightBoxTitle">Required</div>
                </div>
                {primaryCallToActionSelect === undefined && (
                  <button
                    className="callToActionButton"
                    onClick={() => setPrimaryCallToActionSelect("")}
                  >
                    Add Call To Action
                  </button>
                )}
                {primaryCallToActionSelect !== undefined && (
                  <>
                    {" "}
                    <div className="callToActionRemoveContainer">
                      <select
                        className="callToActionDropdown"
                        onChange={(e: any) =>
                          setPrimaryCallToActionSelect(e.target.value)
                        }
                        value={primaryCallToActionSelect}
                      >
                        <option>SELECT</option>
                        <option>EMAIL</option>
                        <option>PHONE</option>
                        <option>VIDEO</option>
                        <option>VISIT_WEBSITE</option>
                        <option>PREPARE</option>
                        <option>SCHEDULE</option>
                      </select>
                      <button
                        onClick={() => handleRemoveCallToAction("Primary")}
                        className="removeCallToAction"
                      >
                        <ClearIcon />
                      </button>
                    </div>
                    {primaryCallToActionSelect &&
                      renderFormForActionType(
                        primaryValues,
                        "Primary",
                        primaryCallToActionSelect
                      )}
                  </>
                )}
              </div>
              {primaryCallToActionSelect && (
                <div className="callToActionIndividualContainer">
                  <div className="darkBoxTitle">
                    Secondary Call To Action
                    <div className="lightBoxTitle">Optional</div>
                  </div>
                  {secondaryCallToActionSelect === undefined && (
                    <button
                      className="callToActionButton"
                      onClick={() => setSecondaryCallToActionSelect("")}
                    >
                      Add Call To Action
                    </button>
                  )}
                  {secondaryCallToActionSelect !== undefined && (
                    <>
                      {" "}
                      <div className="callToActionRemoveContainer">
                        <select
                          className="callToActionDropdown"
                          onChange={(e: any) =>
                            setSecondaryCallToActionSelect(e.target.value)
                          }
                          value={secondaryCallToActionSelect}
                        >
                          <option>SELECT</option>
                          <option>EMAIL</option>
                          <option>PHONE</option>
                          <option>VIDEO</option>
                          <option>VISIT_WEBSITE</option>
                          <option>PREPARE</option>
                          <option>SCHEDULE</option>
                        </select>
                        <button
                          onClick={() => handleRemoveCallToAction("Secondary")}
                          className="removeCallToAction"
                        >
                          <ClearIcon />
                        </button>
                      </div>
                      {secondaryCallToActionSelect &&
                        renderFormForActionType(
                          secondaryValues,
                          "Secondary",
                          secondaryCallToActionSelect
                        )}
                    </>
                  )}
                </div>
              )}
              {secondaryCallToActionSelect && (
                <div className="callToActionIndividualContainer">
                  <div className="darkBoxTitle">
                    Tertiary Call To Action
                    <div className="lightBoxTitle">Optional</div>
                  </div>
                  {tertiaryCallToActionSelect === undefined && (
                    <button
                      className="callToActionButton"
                      onClick={() => setTertiaryCallToActionSelect("")}
                    >
                      Add Call To Action
                    </button>
                  )}
                  {tertiaryCallToActionSelect !== undefined && (
                    <>
                      {" "}
                      <div className="callToActionRemoveContainer">
                        <select
                          className="callToActionDropdown"
                          onChange={(e: any) =>
                            setTertiaryCallToActionSelect(e.target.value)
                          }
                          value={tertiaryCallToActionSelect}
                        >
                          <option>SELECT</option>
                          <option>EMAIL</option>
                          <option>PHONE</option>
                          <option>VIDEO</option>
                          <option>VISIT_WEBSITE</option>
                          <option>PREPARE</option>
                          <option>SCHEDULE</option>
                        </select>
                        <button
                          onClick={() => handleRemoveCallToAction("Tertiary")}
                          className="removeCallToAction"
                        >
                          <ClearIcon />
                        </button>
                      </div>
                      {tertiaryCallToActionSelect &&
                        renderFormForActionType(
                          tertiaryValues,
                          "Tertiary",
                          tertiaryCallToActionSelect
                        )}
                    </>
                  )}
                </div>
              )}
            </div>
            <div className="darkBoxTitle">
              Why This Matters Text *
              <div className="lightBoxTitle">500 character limit</div>
            </div>
            <textarea
              className={editableLargeBox()}
              readOnly={readOnly}
              maxLength={500}
              value={whyMatters}
              placeholder={"Why this CivAction is important"}
              onChange={(e) => setWhyMatters(e.target.value)}
            />
            <div className="darkBoxTitle">
              Take Action Text *
              <div className="lightBoxTitle">500 character limit</div>
            </div>
            <textarea
              className={editableLargeBox()}
              readOnly={readOnly}
              maxLength={500}
              value={takeAction}
              placeholder={
                "Explain what action the user needs to take for this action to be considered complete"
              }
              onChange={(e) => setTakeAction(e.target.value)}
            />
            <div className="darkBoxTitle">
              Share Text *
              <div className="lightBoxTitle">500 character limit</div>
            </div>
            <textarea
              className={editableLargeBox()}
              readOnly={readOnly}
              maxLength={500}
              value={shareText}
              placeholder={
                "What text is used when this action is shared on social"
              }
              onChange={(e) => setShareText(e.target.value)}
            />
            <div />
            <div className="darkBoxTitle">
              Will users be required to complete this action before the next
              action is available?{" "}
              <Checkbox
                defaultChecked={userPrecedentMilestoneBoolean}
                checked={userPrecedentMilestoneBoolean}
                {...label}
                onChange={() => {
                  setUserPrecedentMilestoneBoolean(
                    !userPrecedentMilestoneBoolean
                  );
                }}
              />
            </div>
            <div />
            <div className="createNewButtonContainer">
              {emptyFieldError && !errorMessage && (
                <div className="errorMessageContainerSmall">
                  <h3>ERROR!</h3>
                  Please make sure all fields are filled out before submitting!
                </div>
              )}
              {errorMessage && !successMessage && (
                <div className="errorMessageContainerSmall">
                  <h3>ERROR!</h3>
                  {errorMessage}
                </div>
              )}
              {successMessage && (
                <div className="successMessageContainerSmall">
                  <h3>SUCCESS!</h3>
                  Milestone was created!
                </div>
              )}
              <button
                className="createNewButton"
                onClick={handleAddNewMilestone}
              >
                Create New Milestone
              </button>
            </div>
          </header>
        </div>
      </SmallNavbar>
    </>
  );
}

export default IndividualMilestone;
