import {DialogContent, Divider, Link, Typography} from "@mui/material";
import {useContext} from "react";
import {OrganizationDTO} from "../../models/dto/OrganizationDTO";
import {ApolloLazyQueryContext} from "../query/ApolloLazyQueryDialogContextWrapper";

export default () => {
    const organizationDTO = useContext<OrganizationDTO>(ApolloLazyQueryContext);
    return (
        <DialogContent>
            <Typography variant={"h5"} style={{marginTop:"0.8em"}}> Mission Statement</Typography>
            <Divider/>

            <Typography style={{marginBottom:"1.3em"}}>
                {organizationDTO.mission_statement}
            </Typography>
            <Typography variant={"h5"}> Get Involved Statement</Typography>
            <Divider/>
            <Typography style={{marginBottom:"1.3em"}}>
                {organizationDTO.get_involved_statement}
            </Typography>
            <Typography variant={"h5"}> Share Text</Typography>
            <Divider/>
            <Typography style={{marginBottom:"1.3em"}}>
                {organizationDTO.share_text}
            </Typography>
            <Typography variant={"h5"}> Visit This Organization At </Typography>
            <Divider/>

            <Link
                onClick={() => {
                    // window.open('https://www.google.com', '_blank')
                    //this string appears to need the entire url of the website
                    //eg https://www. ...
                    console.log(organizationDTO.website_url,'<--- website url')
                    window.open("https://www." + `${organizationDTO.website_url}`, '_blank')
                }}
                variant="h6" style={{color:"blue"}}
            >
                {organizationDTO.website_url}
            </Link>
        </DialogContent>
    )
}