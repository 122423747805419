import { gql } from "@apollo/client";

const GET_GOAL_BY_ID = gql`
  query MyQuery($input: GetGoalInput!) {
    GetGoal(input: $input) {
      id
      name
      share_text
      statement
      icon_file_location
    }
  }
`;

export default GET_GOAL_BY_ID;
