import useValidationQuery from "../hooks/useValidationQuery";
import {OrganizationDTO} from "../models/dto/OrganizationDTO";
import {useContext} from "react";
import {UserContext} from "../context/UserContext";
import {UserClass} from "../models/class/UserClass";
import ALL_ORGANIZATIONS from "../GraphQL/Queries/all-organizations";
import OrganizationsMUITable from "../components/organization/OrganizationsMUITable";
import OrganizationPageWrapper from "../components/organization/OrganizationPageWrapper";
import ApolloWrapper from "../components/activity_indicator/ApolloWrapper";
import {LabelKeyObject} from "react-csv/components/CommonPropTypes";
import {HeaderClass} from "../models/class/HeaderClass";

const Organizations = () => {

    const user = useContext<UserClass>(UserContext);

    const headers = [
        {label: "Name", key: "name"},
        {label: "Mission Statement", key: "missionStatement"},
        {label: "Share Text", key: "shareText"},
        {label: "Motto", key: "motto"}
    ] as LabelKeyObject[];

    const headerClass = new HeaderClass(headers);

    const {
        data: organizationsDTO,
        apolloActivityResponse
    } = useValidationQuery<ReadonlyArray<OrganizationDTO>>(ALL_ORGANIZATIONS, {
        id: user.id
    });
    return (
        <OrganizationPageWrapper
            organizationsDTO={organizationsDTO}
            headerClass={headerClass}
        >
            <ApolloWrapper
                apolloActivityResponse={apolloActivityResponse}
            >
                <OrganizationsMUITable
                    organizationsDTO={organizationsDTO}
                    headerClass={headerClass}
                />
            </ApolloWrapper>
        </OrganizationPageWrapper>
    )
}

export default Organizations;
