import {Navigate, Route, Routes, useLocation} from "react-router-dom";
import Users from "../pages/Users";
import MilestoneRoutes from "./MilestoneRoutes";
import MilestoneTypeRoutes from "./MilestoneTypeRoutes";
import OrganizationRoutes from "./OrganizationRoutes";
import GoalRoutes from "./GoalRoutes";
import React from "react";
import AccessDeniedPage from "../pages/AccessDeniedPage";
import {UserClass} from "../models/class/UserClass";
import {UserContext} from "../context/UserContext";
import {UserDTO} from "../models/dto/UserDTO";
import ProfilePage from "../pages/ProfilePage";
import Topbar from "../components/Topbar";

export default ({userDTO}: { userDTO: UserDTO }) => {
    const location = useLocation();
    return (
        <>
            <UserContext.Provider value={new UserClass(userDTO)}>
                {location.pathname !== "/access_denied" && (
                    <Topbar/>
                    // <Navbar links={SidebarData}/>
                    )}
                <Routes>
                    <Route path="*" element={<Navigate to="/" replace/>}/>
                    <Route path="/access_denied" element={<AccessDeniedPage/>}/>
                    <Route index element={<Users/>}/>
                    <Route path="/profile" element={<ProfilePage/>}/>
                    <Route path="/milestones/*" element={<MilestoneRoutes/>}/>
                    <Route path="/milestone_types/*" element={<MilestoneTypeRoutes/>}/>
                    <Route path="/organizations/*" element={<OrganizationRoutes/>}/>
                    <Route path="/goals/*" element={<GoalRoutes/>}/>
                </Routes>
            </UserContext.Provider>
        </>
    )
}