import React from "react";
import "./ActionType.css";

interface PropValues {
  onValueChange?: (name: string, value: any) => void;
}

interface VisitWebsiteInterface {
  website_url: string;
  cta_type: "VISIT_WEBSITE";
}

function ActionTypeVisitWebsite({ values, onValueChange }: any) {
  return (
    <div className="textContainer">
      <div className="darkBoxTitle">
        Website URL
        <div className="lightBoxTitle"></div>
      </div>
      <input
        className="editableSmallInputBoxCTA"
        type="text"
        value={values?.website_url || ""}
        onChange={(e) => onValueChange("website_url", e.target.value)}
        placeholder={"Enter the website URL here"}
      />
    </div>
  );
}

export default ActionTypeVisitWebsite;
