import {CircularProgress} from "@mui/material";
import "../../../App.css";
export default () => {
    return <div className="App">
     <div style={{alignItems:"center",height:175}}>
        <div style={{fontWeight:"500"}}>There Are No Items To Be Shown.</div>
        <div style={{marginTop:10,fontWeight:"500"}}>If you are expecting data, please refresh the application</div>
        <div style={{marginTop:10,fontWeight:"400"}}>If the problem persist, contact customer support</div>
        </div>
    </div>
}