import { gql } from "@apollo/client";

const CREATE_ORGANIZATION = gql`
  mutation MyMutation($input: CreateOrganizationInput!) {
    createOrganization(input: $input) {
      id
    }
  }
`;

export default CREATE_ORGANIZATION;

// {
//   "input": {
//     "name": <STRING>,
//     "motto": <STRING>,
//     "mission_statement": <STRING>,
//     "thumbnail_image_file_location": <STRING>,
//     "civaction_manager_id": 1,
//     "website_url": <STRING>,
//     "get_involved_statement": <STRING>,
//     "share_text": <STRING>,
//     "goal_1": 1,
//     "goal_2": 2,
//     "goal_3": 3,
//     "org_rep_email": ted@gmail.com,
//   }
// }
