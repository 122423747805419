import { gql } from "@apollo/client";

const ALL_ORGANIZATIONS = gql`
    query GetAllOrganizations {
        AllOrganizations {
            id
            name
            motto
            mission_statement
            share_text
            referral_code
            thumbnail_image_file_location
            website_url
            get_involved_statement
        }
    }
`;

export default ALL_ORGANIZATIONS;