import {Route, Routes} from "react-router-dom";
import Organizations from "../pages/Organizations";
import IndividualOrg from "../pages/IndividualOrg";
import IndividualOrgAnalytics from "../pages/IndividualOrgAnalytics";
import React, {useContext} from "react";
import {UserClass} from "../models/class/UserClass";
import {UserContext} from "../context/UserContext";
export default () => {
    const user = useContext<UserClass>(UserContext);
    return (
        <Routes>
            <Route index element={<Organizations />} />
            <Route path="createUpdate" element={<IndividualOrg managerEmail={user.email} />}/>
            <Route path="analytics" element={<IndividualOrgAnalytics />}/>
        </Routes>
    )
}