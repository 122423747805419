import {DialogContent, Divider, Link, Typography} from "@mui/material";
import {useContext} from "react";
import {MilestoneDTO} from "../../models/dto/MilestoneDTO";
import {ApolloLazyQueryContext} from "../query/ApolloLazyQueryDialogContextWrapper";

export default () => {
    const milestoneDTO = useContext<MilestoneDTO>(ApolloLazyQueryContext);
    console.log(milestoneDTO,'organizdt1oinsidenDetai2l')
    return (
        <DialogContent>
            <Typography variant={"h5"} style={{marginTop:"0.8em"}}>Title</Typography>
            <Divider/>

            <Typography style={{marginBottom:"1.3em"}}>
                {milestoneDTO.title}
            </Typography>
            <Typography variant={"h5"}> Description</Typography>
            <Divider/>
            <Typography style={{marginBottom:"1.3em"}}>
                {milestoneDTO.description}
            </Typography>
        </DialogContent>
    )
}