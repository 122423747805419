import useLazyValidationQuery from "../../hooks/useLazyValidationQuery";
import React, {useEffect} from "react";
import {ModalHookInterface} from "../../hooks/useModal";
import {Dialog} from "@mui/material";
import ApolloLazyDialogWrapper from "../activity_indicator/dialog/ApolloLazyDialogWrapper";
import {DocumentNode} from "@apollo/client";

interface ApolloLazyQueryDialogProps {
    modalValues: ModalHookInterface,
    documentNode: DocumentNode,
    children: JSX.Element
    //todo:make this a generic component
}

export const ApolloLazyQueryContext = React.createContext<any | null>(null);

export default (props: ApolloLazyQueryDialogProps) => {
    const {modalValues, documentNode, children} = props;

    const {
        lazyQuery,
        data,
        apolloActivityResponse
    } = useLazyValidationQuery<any>(documentNode);

    useEffect(() => {
        if (!modalValues.visible) return;
        (async () => {
            await lazyQuery(
                {
                    variables: {
                        input: {
                            id: modalValues.modalId
                        }
                    }
                });
        })();
    }, [modalValues.visible])
    return (
        <ApolloLazyQueryContext.Provider
            value={data}
        >
            <Dialog
                open={modalValues.visible}
                onClose={modalValues.toggle}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <ApolloLazyDialogWrapper
                    apolloActivityResponse={apolloActivityResponse}
                    data={data}
                >
                    {children}
                </ApolloLazyDialogWrapper>
            </Dialog>

        </ApolloLazyQueryContext.Provider>
    )

}