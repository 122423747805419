import { gql } from "@apollo/client";

const GET_MILESTONE_BY_ID = gql`
  query MyQuery($input: GetMilestoneInput!) {
    GetMilestone(input: $input) {
      id
      title
      description
      target_number_of_completions
      deadline_timestamp
      completed_timestamp
      goal {
        id
        name
      }
      milestone_type {
        id
        name
        image_file_location
      }
    }
  }
`;

export default GET_MILESTONE_BY_ID;
