import { gql } from "@apollo/client";

const GET_GOAL_MILESTONES_BY_ID = gql`
  query MyQuery($input: GetGoalMilestonesByIdInput!) {
    GetGoalMilestonesById(input: $input) {
      id
      title
    }
  }
`;

export default GET_GOAL_MILESTONES_BY_ID;

// {
//   "input": {
//     "id": 1
//   }
// }
