const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_region": "us-east-1",
    "aws_appsync_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_aMCNLmuqC",
    "aws_user_pools_web_client_id": "3plgqbrhpjlr3o0buggh8cb81t",
    "oauth": {
        "domain": "domain-staging-stage.auth.us-east-1.amazoncognito.com",
        "scope": [
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://manage.staging.civaction.com/",
        "redirectSignOut": "https://manage.staging.civaction.com/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_appsync_graphqlEndpoint": "https://staging.civaction.com/graphql",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
};

export default awsmobile;
