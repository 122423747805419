import React from "react";
import "./ActionType.css";

interface PropValues {
  callToActionIdentifier?: string;
  values?: {
    to_email_address: string;
    subject_template: string;
    body_template: string;
    cta_type: "EMAIL";
  };
  onValueChange?: (name: string, value: any) => void;
}

interface EmailInterface {
  to_email_address: string;
  subject_template: string;
  body_template: string;
  cta_type: string;
}

function ActionTypeEmail({ values, onValueChange }: any) {
  return (
    <>
      <div className="textContainer">
        <div className="darkBoxTitle">
          Email Address
          <div className="lightBoxTitle"></div>
        </div>
        <input
          className="editableSmallInputBoxCTA"
          type="text"
          value={values?.to_email_address || ""}
          onChange={(e) => onValueChange("to_email_address", e.target.value)}
          placeholder={"Enter the receiving email address here"}
        />
        <div className="darkBoxTitle">
          Email Subject Line
          <div className="lightBoxTitle"></div>
        </div>
        <input
          className="editableSmallInputBoxCTA"
          type="text"
          value={values?.subject_template || ""}
          onChange={(e) => onValueChange("subject_template", e.target.value)}
          placeholder={"Enter the email subject line here"}
        />
        <div className="darkBoxTitle">
          Email Body Template
          <div className="lightBoxTitle"></div>
        </div>
        <textarea
          className="editableLargeInputBoxCTA"
          value={values?.body_template || ""}
          placeholder={"Enter the email body template here"}
          onChange={(e) => onValueChange("body_template", e.target.value)}
        />
      </div>
    </>
  );
}

export default ActionTypeEmail;
