import React, { useState, useEffect, useMemo } from "react";
import Checkbox from "@mui/material/Checkbox";
import "./ActionType.css";
import { FaBullseye } from "react-icons/fa";

interface PropValues {
  callToActionIdentifier?: string;
  primaryValues?: {
    date: string;
    time: string;
    is_all_day: boolean;
    length_minutes: string | null;
  };
  onValueChange?: (name: string, value: any) => void;
}

interface ScheduleInterface {
  date: string;
  time: string;
  is_all_day: boolean;
  length_minutes: string | null;
}

function ActionTypeSchedule({ values, onValueChange }: any) {
  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  const checkAllDay = useMemo(() => {
    return values?.is_all_day ? "isAllDay" : "notAllDay";
  }, [values]);

  return (
    <div className="textContainer">
      <div className="darkBoxTitle">
        Schedule Date & Time
        <div className="lightBoxTitle"></div>
      </div>
      <div className="dateTimeInputContainer">
        <input
          className="editableSmallInputBoxDateTimeCTA"
          type="date"
          value={values?.date || ""}
          onChange={(e) => onValueChange("date", e.target.value)}
        />
        <input
          className="editableSmallInputBoxDateTimeCTA"
          type="time"
          value={values?.time || ""}
          onChange={(e) => onValueChange("time", e.target.value)}
        />
      </div>
      <div className="allDayEventContainer">
        <div className="darkBoxTitle">
          Will this be an all day event?
          <div className="lightBoxTitle"></div>
        </div>
        <Checkbox
          defaultChecked={values?.is_all_day || false}
          {...label}
          onChange={() =>
            onValueChange(
              "is_all_day",
              values?.is_all_day === undefined ? true : !values?.is_all_day
            )
          }
          checked={values?.is_all_day || false}
        />
      </div>
      <div className={checkAllDay}>
        <div className="darkBoxTitle">
          Length of Event (Minutes)
          <div className="lightBoxTitle"></div>
        </div>
        <input
          className="editableSmallInputBoxCTA"
          type="text"
          value={values?.length_minutes || ""}
          onChange={(e) => onValueChange("length_minutes", e.target.value)}
          placeholder={"ex. 180"}
        />
      </div>
    </div>
  );
}

export default ActionTypeSchedule;
