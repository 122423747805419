import React, { useState } from "react";
import SmallNavbar from "../components/SmallNavbar";
import * as MdIcons from "react-icons/md";
import "../App.css";
import * as BsIcons from "react-icons/bs";
import { Line, Pie } from "react-chartjs-2";
import { CSVLink } from "react-csv";
import * as HiIcons from "react-icons/hi";

function IndividualOrgAnalytics(props: any) {
  const userData = {
    newUsers: 625,
    totalUsers: 2254,
    dailyScreentime: 5,
    screentimeDuration: 3,
    actions: 4,
    userRetention: 22
  };

  //pull from API
  const newUserWeek = [5, 2, 7, 4, 6, 1, 1];
  const newUserWeekAvg = [2, 3, 8, 3, 7, 2, 2];
  const activeUsersWeek = [25, 30, 32, 29, 36, 41, 31];
  const activeUsersWeekAvg = [20, 31, 32, 39, 37, 42, 32];
  const dailyScreenTimeWeek = [25, 23, 22, 19, 16, 11, 11];
  const dailyScreenTimeWeekAvg = [12, 11, 13, 13, 13, 14, 12];
  const sessionScreenTimeWeek = [5, 3, 2, 9, 6, 1, 1];
  const sessionScreenTimeWeekAvg = [2, 1, 3, 3, 3, 4, 2];
  const actionWeek = [15, 13, 10, 29, 12, 13, 11];
  const actionWeekAvg = [12, 11, 13, 13, 13, 14, 12];
  const userRetentionWeek = [1, 4, 3, 3, 6, -1, -2];
  const userRetentionWeekAvg = [2, 1, 3, -2, 3, 2, 2];

  const newUserMonth = [
    5,
    2,
    7,
    4,
    6,
    1,
    1,
    5,
    2,
    7,
    4,
    6,
    1,
    1,
    5,
    2,
    7,
    4,
    6,
    1,
    1,
    5,
    2,
    7,
    4,
    6,
    1,
    1
  ];
  const newUserMonthAvg = [
    2,
    3,
    8,
    3,
    7,
    2,
    2,
    2,
    3,
    8,
    3,
    7,
    2,
    2,
    2,
    3,
    8,
    3,
    7,
    2,
    2,
    2,
    3,
    8,
    3,
    7,
    2,
    2
  ];
  const activeUsersMonth = [
    25,
    30,
    32,
    29,
    36,
    41,
    31,
    25,
    30,
    32,
    29,
    36,
    41,
    31,
    25,
    30,
    32,
    29,
    36,
    41,
    31,
    25,
    30,
    32,
    29,
    36,
    41,
    31
  ];
  const activeUsersMonthAvg = [
    20,
    31,
    32,
    39,
    37,
    42,
    32,
    20,
    31,
    32,
    39,
    37,
    42,
    32,
    20,
    31,
    32,
    39,
    37,
    42,
    32,
    20,
    31,
    32,
    39,
    37,
    42,
    32
  ];
  const dailyScreenTimeMonth = [
    25,
    23,
    22,
    19,
    16,
    11,
    11,
    25,
    23,
    22,
    19,
    16,
    11,
    11,
    25,
    23,
    22,
    19,
    16,
    11,
    11,
    25,
    23,
    22,
    19,
    16,
    11,
    11
  ];
  const dailyScreenTimeMonthAvg = [
    12,
    11,
    13,
    13,
    13,
    14,
    12,
    12,
    11,
    13,
    13,
    13,
    14,
    12,
    12,
    11,
    13,
    13,
    13,
    14,
    12,
    12,
    11,
    13,
    13,
    13,
    14,
    12
  ];
  const sessionScreenTimeMonth = [
    5,
    3,
    2,
    9,
    6,
    1,
    1,
    5,
    3,
    2,
    9,
    6,
    1,
    1,
    5,
    3,
    2,
    9,
    6,
    1,
    1,
    5,
    3,
    2,
    9,
    6,
    1,
    1
  ];
  const sessionScreenTimeMonthAvg = [
    2,
    1,
    3,
    3,
    3,
    4,
    2,
    2,
    1,
    3,
    3,
    3,
    4,
    2,
    2,
    1,
    3,
    3,
    3,
    4,
    2,
    2,
    1,
    3,
    3,
    3,
    4,
    2
  ];
  const actionMonth = [
    15,
    13,
    10,
    29,
    12,
    13,
    11,
    15,
    13,
    10,
    29,
    12,
    13,
    11,
    15,
    13,
    10,
    29,
    12,
    13,
    11,
    15,
    13,
    10,
    29,
    12,
    13,
    11
  ];
  const actionMonthAvg = [
    12,
    11,
    13,
    13,
    13,
    14,
    12,
    12,
    11,
    13,
    13,
    13,
    14,
    12,
    12,
    11,
    13,
    13,
    13,
    14,
    12,
    12,
    11,
    13,
    13,
    13,
    14,
    12
  ];
  const userRetentionMonth = [
    1,
    4,
    3,
    3,
    6,
    -1,
    -2,
    1,
    4,
    3,
    3,
    6,
    -1,
    -2,
    1,
    4,
    3,
    3,
    6,
    -1,
    -2,
    1,
    4,
    3,
    3,
    6,
    -1,
    -2
  ];
  const userRetentionMonthAvg = [
    2,
    1,
    3,
    -2,
    3,
    2,
    2,
    2,
    1,
    3,
    -2,
    3,
    2,
    2,
    2,
    1,
    3,
    -2,
    3,
    2,
    2,
    2,
    1,
    3,
    -2,
    3,
    2,
    2
  ];

  const WeekData = [
    newUserWeek,
    activeUsersWeek,
    dailyScreenTimeWeek,
    sessionScreenTimeWeek,
    actionWeek,
    userRetentionWeek
  ];
  const WeekDataAvg = [
    newUserWeekAvg,
    activeUsersWeekAvg,
    dailyScreenTimeWeekAvg,
    sessionScreenTimeWeekAvg,
    actionWeekAvg,
    userRetentionWeekAvg
  ];

  const MonthData = [
    newUserMonth,
    activeUsersMonth,
    dailyScreenTimeMonth,
    sessionScreenTimeMonth,
    actionMonth,
    userRetentionMonth
  ];
  const MonthDataAvg = [
    newUserMonthAvg,
    activeUsersMonthAvg,
    dailyScreenTimeMonthAvg,
    sessionScreenTimeMonthAvg,
    actionMonthAvg,
    userRetentionMonthAvg
  ];

  const GraphData = [WeekData, MonthData, WeekData, MonthData]; //fix last two sets of data
  const GraphDataAvg = [WeekDataAvg, MonthDataAvg, WeekDataAvg, MonthDataAvg]; //fix last two sets of data

  let newDate = new Date();

  const months = [
    "January",
    "Febuary",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ];

  let weekLabels = [];
  for (let i = 6; i >= 0; i--) {
    weekLabels.push(newDate.getMonth() + "/" + (newDate.getDate() + i));
  }

  let monthLabels = [];
  for (let i = 27; i >= 0; i--) {
    //hopefully there is a better way to do this so I am going to see if we can just pull it from the database
    monthLabels.push(newDate.getMonth() + "/" + (newDate.getDate() + i));
  }

  let sixMonthsLabels = [];
  for (let i = 5; i >= 0; i--) {
    let currentMonth = newDate.getMonth() - i;
    if (currentMonth < 0) {
      currentMonth = currentMonth + 12;
    }
    sixMonthsLabels.push(months[currentMonth]);
  }

  const labels = [weekLabels, monthLabels, sixMonthsLabels, sixMonthsLabels]; //update last one to all

  const [currentTab, setCurrentTab] = useState(0);
  const [curData, setCurData] = useState(GraphData[0][0]);
  const [curDataAvg, setCurDataAvg] = useState(GraphDataAvg[0][0]);
  const [curLabels, setCurLabels] = useState(labels[0]);
  const [curTime, setTime] = useState(0);
  const [currentPieTab, setCurrentPieTab] = useState(0);
  const [pieChartDescription, setPieChartDescription] = useState(
    "Of the CivActors that subscribe to this goal, what other goals do these users commonly subscribe to?"
  );

  const lineData = {
    labels: curLabels,
    datasets: [
      {
        label: "This Organization",
        data: curData,
        fill: false,
        backgroundColor: "rgb(54, 162, 235)",
        borderColor: "rgba(54, 162, 235, 0.7)"
      },
      {
        label: "Average Organization",
        data: curDataAvg,
        fill: false,
        backgroundColor: "rgb(54, 162, 235, 0.5)",
        borderColor: "rgba(54, 162, 235, 0.2)",
        borderDash: [10, 5]
      }
    ]
  };

  const lineOptions = {
    plugins: {
      legend: {
        display: false
      },
      myScale: {
        position: "right"
      }
    }
  };

  const goalLabels = ["Goal 1", "Goal 2", "Goal 3", "Goal 4"];
  const goalData = [5, 10, 10, 30];
  const orgLabels = [
    "Org 1",
    "Org 2",
    "Org 3",
    "Org 4",
    "Org 5",
    "Org 6",
    "Org 7"
  ];
  const orgData = [5, 10, 10, 30, 25, 20, 12];
  const countyLabels = [
    "County 1",
    "County 2",
    "County 3",
    "County 4",
    "County 5",
    "County 6"
  ];
  const countyData = [5, 10, 10, 30, 25, 20];
  const pieLabels = [goalLabels, orgLabels, countyLabels];
  const pieDataSets = [goalData, orgData, countyData];

  const blues: string[] = [
    "rgba(48,53,110,1)",
    "rgba(47,96,153,1)",
    "rgba(44,140,187,1)",
    "rgba(58,184,215,1)",
    "rgba(128,208,217,1)",
    "rgb(102,135,164, 1)",
    "rgba(75, 119, 190, 1)",
    "rgba(58, 83, 155, 1 )",
    "rgba(52, 152, 219, 1)",
    "rgba(44, 130, 180, 1)",
    "rgba(44,120,187,1)",
    "rgba(58,184,205,1)",
    "rgba(128,200,212,1)",
    "rgb(100,120,164, 1)"
  ];

  var colors: any[] = [[], [], []];
  for (let i = 0; i < 3; i++) {
    for (let j = 0; j < pieLabels[i].length; j++) {
      colors[i].push(blues[j]);
    }
  }

  const pieData = {
    labels: pieLabels[currentPieTab],
    datasets: [
      {
        data: pieDataSets[currentPieTab],
        backgroundColor: colors[currentPieTab],
        borderColor: colors[currentPieTab],
        borderWidth: 1
      }
    ]
  };

  function avgDeviation(avg: number) {
    if (avg >= 0) {
      return (
        <div style={{ color: "green" }}>
          <BsIcons.BsArrowUp />
          {avg}%
        </div>
      );
    } else {
      return (
        <div style={{ color: "red" }}>
          <BsIcons.BsArrowDown />
          {-avg}%
        </div>
      );
    }
  }

  function differentGraph(index: number) {
    setCurrentTab(index);
    setCurData(GraphData[curTime][index]);
    setCurDataAvg(GraphDataAvg[curTime][index]);
  }

  function differentTimeframe(time: string) {
    var currentTime = 0;
    if (time === "0") {
      setTime(0);
      currentTime = 0;
    } else if (time === "1") {
      setTime(1);
      currentTime = 1;
    } else if (time === "2") {
      setTime(2);
      currentTime = 2;
    } else if (time === "3") {
      setTime(3);
      currentTime = 3;
    }
    setCurData(GraphData[currentTime][currentTab]);
    setCurDataAvg(GraphDataAvg[currentTime][currentTab]);
    setCurLabels(labels[currentTime]);
  }

  function differentPieGraph(index: number) {
    setCurrentPieTab(index);
    if (index === 0) {
      setPieChartDescription(
        "Of the CivActors that subscribe to this organization, what other goals do these users commonly subscribe to?"
      );
    } else if (index === 1) {
      setPieChartDescription(
        "Of the CivActors that subscribe to this organization, what other organizations do these users commonly subscribe to?"
      );
    } else {
      setPieChartDescription(
        "Of the CivActors that subscribe to this organization, where are these users most commonly located?"
      );
    }
  }

  function topLine(index: number) {
    if (index === currentTab) {
      return "usersOptionBoxSelected";
    } else {
      return "usersOptionBox";
    }
  }

  function topPieLine(index: number) {
    if (index === currentPieTab) {
      return "usersOptionBoxSelected";
    } else {
      return "usersOptionBox";
    }
  }

  return (
    <SmallNavbar type="Organization" name={"REPLACE ME"} index={1}>
      <div className="App">
        <header className="App-header">
          <div className="pageHeader">
            <h1 className="pageTitle">
              <MdIcons.MdGroups className="groupIcon" />
              {"REPLACE ME"}
            </h1>
          </div>
          <div className="smallUserBox">
            <div className="smallUsersOptionBoxs">
              <div className={topLine(0)} onClick={() => differentGraph(0)}>
                <div className="usersOptionTitle">CivActor Subs Gained</div>
                <div className="usersOptionNumber">{userData.newUsers}</div>
                {avgDeviation(-22)}
              </div>
              <div className={topLine(1)} onClick={() => differentGraph(1)}>
                <div className="usersOptionTitle">Referred CivActors</div>
                <div className="usersOptionNumber">{userData.totalUsers}</div>
                {avgDeviation(23)}
              </div>
              <div className={topLine(2)} onClick={() => differentGraph(2)}>
                <div className="usersOptionTitle">Shares</div>
                <div className="usersOptionNumber">
                  {userData.dailyScreentime}
                </div>
                {avgDeviation(-4.7)}
              </div>
              <div className={topLine(3)} onClick={() => differentGraph(3)}>
                <div className="usersOptionTitle">Websites Clicks</div>
                <div className="usersOptionNumber">
                  {userData.screentimeDuration}
                </div>
                {avgDeviation(14.5)}
              </div>
              <CSVLink
                //update once I am correctly pulling data
                data={[curData]}
                headers={curLabels}
                filename={"CivActionGraphData.csv"}
              >
                <HiIcons.HiDownload
                  className="downloadButtonSmallGraph" /*Onclick need to download data as csv*/
                />
              </CSVLink>
            </div>

            <Line data={lineData} options={lineOptions} />
            <div className="graphDropdownDiv">
              <select
                className="graphTimeDropDown"
                onChange={e => differentTimeframe(e.target.value)}
                value={curTime}
              >
                <option value="0">Last 7 days</option>
                <option value="1">Last 28 days</option>
                <option value="2">Last 6 months</option>
                <option value="3">All</option>
              </select>
            </div>
          </div>
          <div className="subTitleAttributes">Demographic Breakdowns</div>
          <div className="smallestUserBox">
            <div className="smallestUsersOptionBoxs">
              <div
                className={topPieLine(0)}
                onClick={() => differentPieGraph(0)}
              >
                <div className="usersOptionTitle">Top Goals</div>
              </div>
              <div
                className={topPieLine(1)}
                onClick={() => differentPieGraph(1)}
              >
                <div className="usersOptionTitle">Top Orgs</div>
              </div>
              <div
                className={topPieLine(2)}
                onClick={() => differentPieGraph(2)}
              >
                <div className="usersOptionTitle">By County</div>
              </div>
            </div>
            <div>{pieChartDescription}</div>
            <div className="pieChart">
              <Pie
                data={pieData}
                options={{
                  plugins: {
                    legend: {
                      position: "right"
                    }
                  }
                }}
              />
            </div>
          </div>
        </header>
      </div>
    </SmallNavbar>
  );
}

export default IndividualOrgAnalytics;
