import {ApolloActivityResponse} from "../../../hooks/useValidationQuery";
import ActivityDialogIndicatorComponent from "./ActivityDialogIndicatorComponent";

export interface ApolloResponseWithChildrenInterface {
    apolloActivityResponse: ApolloActivityResponse;
    data:any;
    children: JSX.Element;
}
export default ({apolloActivityResponse,data, children}:ApolloResponseWithChildrenInterface) => {
    const {
        refetch,
    } = apolloActivityResponse;

    return (
        <ActivityDialogIndicatorComponent
            apolloActivityResponse={apolloActivityResponse}
            data={data}
        >
            {children}
        </ActivityDialogIndicatorComponent>
    )
};