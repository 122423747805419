import React, { useState, useEffect } from "react";
import SmallNavbar from "../components/SmallNavbar";
import * as MdIcons from "react-icons/md";
import * as BsIcons from "react-icons/bs";
import Modal from "react-modal";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useQuery, useMutation } from "@apollo/client";
import CREATE_ORGANIZATION from "./../GraphQL/Mutations/Create_Organization/create-organization";
import ALL_GOALS from "../GraphQL/Queries/All_Goals/all-goals";
import "../App.css";

interface Goal {
  id: number;
  key: number;
  name: string;
  column: string;
}

interface MappedGoal {
  id: number;
  key: number;
  name: string;
  column: string;
}

function IndividualOrg(props: any) {
  const [orgName, setOrgName] = useState("New Organization");
  const [orgLogo, setOrgLogo] = useState(""); //pull from api using name?
  const [pageURL, setPageURL] = useState("");
  const [slogan, setSlogan] = useState("");
  const [mission, setMission] = useState("");
  const [getInvolved, setGetInvolved] = useState("");
  const [share, setShare] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [readOnly, setReadOnly] = useState(true);
  const [emptyFieldError, setEmptyFieldError] = useState(false);
  const [orgManagerEmail, setOrgManagerEmail] = useState("");
  const [goals, setGoals] = useState<Goal[]>([]);
  const [mappedGoals, setMappedGoals] = useState<MappedGoal[]>([]);
  const { data: all_goals, loading: isLoading } = useQuery(ALL_GOALS);
  const [addNewOrganization] = useMutation(CREATE_ORGANIZATION);
  const [goalOne, setGoalOne] = useState("");
  const [goalTwo, setGoalTwo] = useState("");
  const [goalThree, setGoalThree] = useState("");
  const [goalOneId, setGoalOneId] = useState<number | null>(null);
  const [goalTwoId, setGoalTwoId] = useState<number | null>(null);
  const [goalThreeId, setGoalThreeId] = useState<number | null>(null);
  const [successMessage, setSuccessMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");


  useEffect(() => {
    if (goals && !isLoading) {
      setGoals(all_goals?.AllGoals);

      const mapGoals = () => {
        return goals?.map((goal: any, index: number) => {
          let columnName;

          switch (index) {
            case 0:
              setGoalOne(goal.name);
              setGoalOneId(goal.id);
              break;
            case 1:
              setGoalTwo(goal.name);
              setGoalTwoId(goal.id);
              break;
            case 2:
              setGoalThree(goal.name);
              setGoalThreeId(goal.id);
          }

          if (index <= 2) {
            columnName = `Goal ${index + 1}`;
          } else {
            columnName = "Available Goals";
          }
          return {
            ...goal,
            id: index + 1,
            key: columnName + "-" + (index + 1),
            column: columnName,
          };
        });
      };
      setMappedGoals(mapGoals());
    }
  }, [all_goals?.AllGoals, goals, isLoading]);

  const handleAddNewOrg = (e: any) => {
    e.preventDefault();
    console.log(
      orgName,
      slogan,
      mission,
      orgLogo,
      pageURL,
      getInvolved,
      share,
      goalOne,
      goalTwo,
      goalThree,
      contactEmail,
      orgManagerEmail
    );
    let fieldsToCheck = [
      orgName,
      slogan,
      mission,
      orgLogo,
      pageURL,
      getInvolved,
      share,
      goalOne,
      contactEmail,
      orgManagerEmail,
    ];

    // checks if any input values are empty
    const checkForEmptyFields = () => {
      const missingInput = fieldsToCheck.filter(
        (item: string | number) => !item && item !== 0
      );
      return missingInput.length ? true : false;
    };

    if (checkForEmptyFields()) {
      console.log("Please check that all fields are filled before submitting!");
      setEmptyFieldError(true);
    } else {
      addNewOrganization({
        variables: {
          input: {
            name: orgName,
            motto: slogan,
            mission_statement: mission,
            thumbnail_image_file_location: orgLogo,
            website_url: pageURL,
            get_involved_statement: getInvolved,
            share_text: share,
            goal_1: goalOneId,
            goal_2: goalTwoId,
            goal_3: goalThreeId,
            org_rep_email: contactEmail,
            org_manager_email: orgManagerEmail,
          },
          authMode: "AMAZON_COGNITO_USER_POOLS",
        },
      })
        .then(() => {
          setEmptyFieldError(false);
          setSuccessMessage(true);
          setOrgName("");
          setSlogan("");
          setMission("");
          setOrgLogo("");
          setPageURL("");
          setGetInvolved("");
          setShare("");
          setContactEmail("");
          setOrgManagerEmail("");
        })
        .catch((e) => {
          setErrorMessage(e.message);
          console.log(e);
        });
    }
  };

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: "rgb(58, 58, 58)",
      borderRadius: "10px",
      width: "50%",
    },
  };

  if (orgName === "New Organization") {
    setOrgName("");
    setReadOnly(false);
  }

  // if (orgName === "/organization") {
  //   setOrgName("");
  //   setReadOnly(false);
  // }

  function editableSmallBox() {
    if (readOnly) {
      return "smallInputBox";
    } else {
      return "editableSmallInputBox";
    }
  }

  function editableLargeBox() {
    if (readOnly) {
      return "largeInputBox";
    } else {
      return "editableLargeInputBox";
    }
  }

  function title() {
    return "New Organization";
  }

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  const MovableItem = ({
    id,
    name,
    setMappedGoals,
    column,
  }: {
    id: number;
    name: string;
    setMappedGoals: any;
    column: string;
  }) => {
    const changeItemColumn = (currentItem: any, columnName: string) => {
      setMappedGoals((prevState: any) => {
        return prevState.map((e: any) => {
          if (e.column === columnName) e.column = "Available Goals";
          return {
            ...e,
            column: e.name === name ? columnName : e.column,
          };
        });
      });
    };

    switch (column) {
      case "Goal 1":
        setGoalOne(name);
        setGoalOneId(id);
        break;
      case "Goal 2":
        setGoalTwo(name);
        setGoalTwoId(id);
        break;
      case "Goal 3":
        setGoalThree(name);
        setGoalThreeId(id);
    }

    var className;
    if (column === "Available Goals") {
      className = "goalMovableItemOption";
    } else {
      className = "goalMovableItemTop";
    }

    const [{ isDragging }, drag] = useDrag({
      type: "Our first type",
      end: (item, monitor) => {
        const dropResult: any = monitor.getDropResult();

        if (dropResult && dropResult.name === "Available Goals") {
          changeItemColumn(item, "Available Goals");
          className = "goalMovableItemOption";
        } else if (dropResult && dropResult.name === "Goal 1") {
          changeItemColumn(item, "Goal 1");
          className = "goalMovableItemTop";
        } else if (dropResult && dropResult.name === "Goal 2") {
          changeItemColumn(item, "Goal 2");
          className = "goalMovableItemTop";
        } else if (dropResult && dropResult.name === "Goal 3") {
          changeItemColumn(item, "Goal 3");
          className = "goalMovableItemTop";
        }
      },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    });

    const opacity = isDragging ? 0.4 : 1;

    return (
      <div ref={drag} className={className} style={{ opacity }}>
        {name}
      </div>
    );
  };

  const returnItemsForColumn = (columnName: string) => {
    return mappedGoals
      .filter((item) => item.column === columnName)
      .map((item) => (
        <MovableItem
          id={item.id}
          key={columnName + "-" + item.id}
          name={item.name}
          setMappedGoals={setMappedGoals}
          column={item.column}
        />
      ));
  };

  const Column = ({
    children,
    className,
    title,
  }: {
    children: any;
    className: string;
    title: string;
  }) => {
    const [, drop] = useDrop({
      accept: "Our first type",
      drop: () => ({
        name: title,
      }),
    });
    return (
      <div ref={drop} className={className}>
        {children}
      </div>
    );
  };

  return (
    <>
      <SmallNavbar type="Organization" name={orgName} index={0}>
        <div className="App">
          <header className="App-header">
            <h1 className="pageTitle">
              <MdIcons.MdGroups className="groupIcon" />
              {title()}
              <BsIcons.BsPencilFill
                className="editButton"
                onClick={() => setReadOnly(!readOnly)}
              />
              <BsIcons.BsThreeDots
                className="dotdotdotButton"
                onClick={() => setShowDropdown(!showDropdown)}
              />
              <div
                className={
                  showDropdown ? "active-dotDotDotArrow" : "dotDotDotArrow"
                }
              >
                <BsIcons.BsFillTriangleFill />
              </div>
              <div
                className={
                  showDropdown
                    ? "active-dotDotDotDropdown"
                    : "dotDotDotDropdown"
                }
              >
                <button className="dropDownOption">Archive Organization</button>
                <button className="dropDownOption">Copy Referral Code</button>
                <button className="dropDownOption">Download Analytics</button>
              </div>
            </h1>
            <div className="subTitleAttributes">Organization Attributes</div>
            <div className="darkBoxTitle">
              Organization Name *
              <div className="lightBoxTitle">25 character limit</div>
            </div>
            <input
              className={editableSmallBox()}
              readOnly={readOnly}
              type="text"
              maxLength={25}
              value={orgName}
              placeholder={"Enter organization name"}
              onChange={(e) => setOrgName(e.target.value)}
            />
            <div className="darkBoxTitle">
              Organization Logo *
              {/* <div className="lightBoxTitle">.png or .avg --- 3mb limit</div> */}
            </div>
            {/* <input
              className={editableSmallBox()}
              readOnly={readOnly}
              type="file"
              value={orgLogo}
              onChange={(e) => setOrgLogo(e.target.value)}
            /> */}
            <input
              className={editableSmallBox()}
              readOnly={readOnly}
              type="text"
              value={orgLogo}
              placeholder={"URL to icon"}
              onChange={(e) => setOrgLogo(e.target.value)}
            />
            <div className="darkBoxTitle">Goals *</div>
            <Modal
              isOpen={modalIsOpen}
              onRequestClose={closeModal}
              style={customStyles}
              contentLabel="Bug Modal"
            >
              <div className="modalTitle">Organization Goals</div>
              <div className="modalText">
                Here you can choose which Goals your Organization subscribes to
                by dragging and dropping.
              </div>
              <div className="goalColumnsDiv">
                <DndProvider backend={HTML5Backend}>
                  {/* <div className="goalsTopNumbers">
                    <div className="goalModalNumber">1</div>
                    <div className="goalModalNumber">2</div>
                    <div className="goalModalNumber">3</div>
                  </div> */}
                  <div className="goalModalSections">
                    <div className="goalColumnTitle">Top 3 Goals</div>
                    <div className="topThreeGoalsItem">
                      <span className="TopThreeGoalsNumber">1</span>
                      <Column title="Goal 1" className="goalsTopColumn">
                        {returnItemsForColumn("Goal 1")}
                      </Column>
                    </div>
                    <div className="topThreeGoalsItem">
                      <span className="TopThreeGoalsNumber">2</span>
                      <Column title="Goal 2" className="goalsTopColumn">
                        {returnItemsForColumn("Goal 2")}
                      </Column>
                    </div>{" "}
                    <div className="topThreeGoalsItem">
                      <span className="TopThreeGoalsNumber">3</span>
                      <Column title="Goal 3" className="goalsTopColumn">
                        {returnItemsForColumn("Goal 3")}
                      </Column>
                    </div>
                  </div>
                  <div className="goalModalSections">
                    <div className="goalColumnTitle">Available Goals</div>
                    <Column
                      title="Available Goals"
                      className="goalsOptionsColumn"
                    >
                      {returnItemsForColumn("Available Goals")}
                    </Column>
                  </div>
                </DndProvider>
              </div>
              <div className="sendOrSaveDiv">
                <button
                  onClick={closeModal}
                  className="sendButton" /*where is this sent to?*/
                >
                  Save & Publish
                </button>
                <button
                  onClick={closeModal}
                  className="saveButton" /*where is this saved to?*/
                >
                  Discard Changes
                </button>
              </div>
            </Modal>
            <div
              style={{ display: "flex", flexDirection: "row" }}
              onClick={openModal}
            >
              <div className="goalsBox">
                <div className="goalRow">
                  <div className="goalNumber">1</div>
                  <div className="goalName">{goalOne}</div>
                </div>
                <div className="goalRow">
                  <div className="goalNumber">2</div>
                  <div className="goalName">{goalTwo}</div>
                </div>
                <div className="goalRow">
                  <div className="goalNumber">3</div>
                  <div className="goalName">{goalThree}</div>
                </div>
              </div>
              <div className="lightBoxTitleLong">
                These are your Organization's top 3 goals. These are used to
                help CivActors identify organizations that share common goals.
              </div>
            </div>
            <div className="darkBoxTitle">Page URL *</div>
            <input
              className={editableSmallBox()}
              readOnly={readOnly}
              type="text"
              value={pageURL}
              placeholder={"Page URL added here"}
              onChange={(e) => setPageURL(e.target.value)}
            />
            <div className="darkBoxTitle">Slogan *</div>
            <input
              className={editableSmallBox()}
              readOnly={readOnly}
              type="text"
              value={slogan}
              placeholder={"Input slogan here"}
              onChange={(e) => setSlogan(e.target.value)}
            />
            <div className="darkBoxTitle">
              Mission Text
              <div className="lightBoxTitle">500 character limit *</div>
            </div>
            <textarea
              className={editableLargeBox()}
              readOnly={readOnly}
              value={mission}
              maxLength={500}
              placeholder={
                "Short description of Organization's mission statement goes here"
              }
              onChange={(e) => setMission(e.target.value)}
            />
            <div className="darkBoxTitle">
              Get Involved Text *
              <div className="lightBoxTitle">500 character limit</div>
            </div>
            <textarea
              className={editableLargeBox()}
              readOnly={readOnly}
              value={getInvolved}
              maxLength={500}
              placeholder={
                "Tell the CivActor the best way for them to get involved with your organizations, whether that is going to a local meeting, website, joining the email list, etc."
              }
              onChange={(e) => setGetInvolved(e.target.value)}
            />
            <div className="darkBoxTitle">
              Share Text *
              <div className="lightBoxTitle">250 character limit</div>
            </div>
            <textarea
              className={editableLargeBox()}
              readOnly={readOnly}
              value={share}
              maxLength={250}
              placeholder={
                "What text is used when your organization's page is shared on social media"
              }
              onChange={(e) => setShare(e.target.value)}
            />
            <div className="darkBackground">
              <div className="subtitleWhite">
                Organization Representative Information
              </div>
              <div className="lightBoxTitle">
                This contact information is for CivAction only and is not
                publicly visible
              </div>
              {/* <div className="whiteInputTitle">
                Name <div className="lightBoxTitle">25 character limit</div>
              </div>
              <input
                className={editableSmallBox()}
                readOnly={readOnly}
                type="text"
                value={contactName}
                maxLength={25}
                placeholder={"Rep name"}
                onChange={(e) => setContactName(e.target.value)}
              /> */}
              <div className="whiteInputTitle">
                Organization Contact Email *
                <div className="lightBoxTitle">45 character limit</div>
              </div>
              <input
                className={editableSmallBox()}
                readOnly={readOnly}
                type="text"
                value={contactEmail}
                maxLength={45}
                placeholder={"Input email here"}
                onChange={(e) => setContactEmail(e.target.value)}
              />
              <div className="whiteInputTitle">
                Organization Manager Email *
                <div className="lightBoxTitle">45 character limit</div>
              </div>
              <input
                className={editableSmallBox()}
                readOnly={readOnly}
                type="text"
                value={orgManagerEmail}
                maxLength={45}
                placeholder={"Input email here"}
                onChange={(e) => setOrgManagerEmail(e.target.value)}
              />
              {/* <div className="whiteInputTitle">
                Contact Phone Number{" "}
                <div className="lightBoxTitle">25 character limit</div>
              </div>
              <input
                className={editableSmallBox()}
                readOnly={readOnly}
                type="text"
                value={contactPhoneNumber}
                maxLength={25}
                placeholder={"Input phone here"}
                onChange={(e) => setContactPhoneNumber(e.target.value)}
              /> */}
            </div>
            <div className="createNewButtonContainer">
              {emptyFieldError && !errorMessage && (
                <div className="errorMessageContainerSmall">
                  <h3>ERROR!</h3>
                  Please make sure all fields are filled out before submitting!
                </div>
              )}
              {errorMessage && !successMessage && (
                <div className="errorMessageContainerSmall">
                  <h3>ERROR!</h3>
                  {errorMessage}
                </div>
              )}
              {successMessage && (
                <div className="successMessageContainerSmall">
                  <h3>SUCCESS!</h3>
                  Organization was created!
                </div>
              )}
              <button className="createNewButton" onClick={handleAddNewOrg}>
                Create New Organization
              </button>
            </div>
          </header>
        </div>
      </SmallNavbar>
    </>
  );
}

export default IndividualOrg;
