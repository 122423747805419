import React, { useState } from "react";
import { Link } from "react-router-dom";
import { SidebarData } from "./SidebarData";
import ReportBug from "./ReportBug";
import "./Navbar.css";

function Navbar(props: any) {
  const [currentScreen, setCurrentScreen] = useState(props.index);

  function settings(index: number) {
    if (currentScreen === index) {
      return "nav-text-selected";
    } else {
      return "nav-text";
    }
  }

  return (
    <>
      <nav className="nav-menu">
        <ul className="nav-menu-items">
          {SidebarData.map((item, index) => {
            return (
              <Link
                to={item.path}
                key={index}
                className={settings(index)}
                onClick={() => setCurrentScreen(index)}
              >
                {item.icon}
                <span>{item.title}</span>
              </Link>
            );
          })}
        </ul>
      </nav>
      <>{props.children}</>
      <ReportBug />
    </>
  );
}

export default Navbar;
