import React, { useState } from "react";
import * as AiIcons from "react-icons/ai";
import "./ReportBug.css";
import Modal from "react-modal";

function ReportBug() {
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [bugInfo, setBugInfo] = useState("");

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: "rgb(58, 58, 58)",
      borderRadius: "10px"
    }
  };

  return (
    <>
      <div className="bugDiv" onClick={openModal}>
        <AiIcons.AiFillBug className="bugIcon" />
        <div className="bugButton">Report Bug</div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Bug Modal"
      >
        <div className="bugTitle">Report Bug</div>
        <div className="bugFinePrint">
          Describe the steps leading up to the bug you are experiencing, and
          what the issue is.
        </div>
        <textarea
          value={bugInfo}
          className="bugInfo"
          onChange={e => setBugInfo(e.target.value)}
        />
        <div className="bugFinePrint">Attach screenshots of the bug</div>
        <div>
          <input className="chooseFileButton" type="file" />
        </div>
        <div>
          <input className="chooseFileButton" type="file" />
        </div>
        <div>
          <input className="chooseFileButton" type="file" />
        </div>
        <div className="sendOrSaveDiv">
          <button
            onClick={closeModal}
            className="sendButton" /*where is this sent to?*/
          >
            Send Report
          </button>
          <button
            onClick={closeModal}
            className="saveButton" /*where is this saved to?*/
          >
            Save for Later
          </button>
        </div>
        <div className="bugEmailInfo">
          You can also email support@civaction.com for help
        </div>
      </Modal>
    </>
  );
}

export default ReportBug;
