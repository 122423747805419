import {LabelKeyObject} from "react-csv/components/CommonPropTypes";

export class HeaderClass {

    constructor(
        private readonly _labelKeyObjects:LabelKeyObject[]
    )
    {}
    //TODO use this for the CSV as this accepts that object as a header
    //having difficulty with
    get labelKeyObjects(): LabelKeyObject[] {
        return this._labelKeyObjects;
    }
    get labels():ReadonlyArray<string> {
        return this._labelKeyObjects.map(header => header.label) as ReadonlyArray<string>;
    }


}