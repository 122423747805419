import React from "react";
import * as FaIcons from "react-icons/fa";
import * as IoIcons from "react-icons/io";
import * as MdIcons from "react-icons/md";
import * as FiIcons from "react-icons/fi";

export const SidebarData = [
  {
    title: "Users",
    path: "/",
    icon: <IoIcons.IoMdPerson />,
    className: "nav-text"
  },
  {
    title: "Milestones",
    path: "/milestones",
    icon: <FaIcons.FaFlag />,
    className: "nav-text"
  },
  {
    title: "Goals",
    path: "/goals",
    icon: <FiIcons.FiTarget />,
    className: "nav-text"
  },
  {
    title: "Organizations",
    path: "/organizations",
    icon: <MdIcons.MdGroups />,
    className: "nav-text"
  },
  {
    title: "Milestone Types",
    path: "/milestone_types",
    icon: <IoIcons.IoMdPerson />,
    className: "nav-text"
  }
];
