import { gql } from "@apollo/client";

const ALL_MILESTONE_TYPES = gql`
  query GetAllMilestoneTypes {
    AllMilestoneTypes {
      id
      name
    }
  }
`;

export default ALL_MILESTONE_TYPES;
