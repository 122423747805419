import {DialogContent, Divider, Link, Typography} from "@mui/material";
import {useContext} from "react";
import {GoalDTO} from "../../models/dto/GoalDTO";
import {ApolloLazyQueryContext} from "../query/ApolloLazyQueryDialogContextWrapper";

export default () => {
    const goalDTO =  useContext<GoalDTO>(ApolloLazyQueryContext);
    console.log(goalDTO,'goalDIALOGO')
    return (
        <DialogContent>
            <Typography variant={"h5"} style={{marginTop:"0.8em"}}> Goal Name </Typography>
            <Divider/>

            <Typography style={{marginBottom:"1.3em"}}>
                {goalDTO.name}
            </Typography>
            <Typography variant={"h5"}> Share Text</Typography>
            <Divider/>
            <Typography style={{marginBottom:"1.3em"}}>
                {goalDTO.share_text}
            </Typography>
            <Typography variant={"h5"}>Statement</Typography>
            <Divider/>
            <Typography style={{marginBottom:"1.3em"}}>
                {goalDTO.statement}
            </Typography>
        </DialogContent>
    )
}