import React, { useState, useEffect, ChangeEvent } from "react";
import MuiPhoneNumber from "material-ui-phone-number";
import "./ActionType.css";

interface PropValues {
  callToActionIdentifier?: string;
  values?: {
    phone_number: string;
    cta_type: "PHONE";
  };
  onValueChange?: (name: string, value: any) => void;
}

interface PhoneInterface {
  phone_number: string | ChangeEvent<HTMLTextAreaElement | HTMLInputElement>;
}

function ActionTypePhone({ values, onValueChange }: any) {
  return (
    <div className="textContainer">
      <div className="darkBoxTitle">
        Phone Number
        <div className="lightBoxTitle"></div>
      </div>
      <MuiPhoneNumber
        className="test"
        value={values?.phone_number || ""}
        defaultCountry={"us"}
        onChange={(e) => onValueChange("phone_number", e)}
      />
    </div>
  );
}

export default ActionTypePhone;
