import React, {useContext, useState} from "react";
import "rsuite-table/dist/css/rsuite-table.css";
import "../App.css";
import {UserClass} from "../models/class/UserClass";
import {UserContext} from "../context/UserContext";
import {LabelKeyObject} from "react-csv/components/CommonPropTypes";
import useValidationQuery from "../hooks/useValidationQuery";
import ApolloWrapper from "../components/activity_indicator/ApolloWrapper";
import GoalMUITable from "../components/goal/GoalMUITable";
import {HeaderClass} from "../models/class/HeaderClass";
import GoalPageWrapper from "../components/goal/GoalPageWrapper";
import ALL_GOALS from "../GraphQL/Queries/All_Goals/all-goals";
import {GoalDTO} from "../models/dto/GoalDTO";

function Goals() {

    const user = useContext<UserClass>(UserContext);

    const headers = [
        {label: "Name", key: "name"}
    ] as LabelKeyObject[];

    const headerClass = new HeaderClass(headers);

    const {
        data: goalDTO,
        apolloActivityResponse
    } = useValidationQuery<ReadonlyArray<GoalDTO>>(ALL_GOALS, {
        id: user.id
    });
    return (

        <GoalPageWrapper
            headerClass={headerClass}
        >
            <ApolloWrapper
                apolloActivityResponse={apolloActivityResponse}
            >
                <GoalMUITable
                    goalDTO={goalDTO}
                    headerClass={headerClass}
                />
            </ApolloWrapper>
        </GoalPageWrapper>
    );
}

export default Goals;
