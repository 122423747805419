import React, { useState, useEffect } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import ALL_MILESTONE_TYPES from "./../GraphQL/Queries/All_Milestone_Types/all-milestone-types";
import { useQuery } from "@apollo/client";

interface PropValues {

  handleMilestoneType: (e: any, values: any) => void;
}

interface MilestoneType {
  id: number;
  name: string;
  key: number;
  item_file_location: string;
}

export default function Tags({ handleMilestoneType }: any) {
  const [milestoneTypeList, setMilestoneTypeList] = useState<MilestoneType[]>(
    []
  );

  const { data: all_milestone_types, loading: milestoneTypesLoading } =
    useQuery(ALL_MILESTONE_TYPES);

  useEffect(() => {
    if (all_milestone_types && !milestoneTypesLoading)
      setMilestoneTypeList(all_milestone_types?.AllMilestoneTypes);
  }, [
    all_milestone_types?.AllMilestoneTypes,
    milestoneTypeList,
    milestoneTypesLoading,
  ]);

  return (
    <Stack spacing={3} sx={{ width: 612 }}>
      <Autocomplete
        disablePortal
        id="combo-box-demo"
        options={milestoneTypeList}
        onChange={handleMilestoneType}
        getOptionLabel={(option) => option.name}
        sx={{ width: 300 }}
        renderInput={(params) => <TextField {...params} label="Select" />}
      />
    </Stack>
  );
}
