import React, { useState, useEffect } from "react";
import SmallNavbar from "../components/SmallNavbar";
import * as FiIcons from "react-icons/fi";
import * as BsIcons from "react-icons/bs";
import { useMutation } from "@apollo/client";
import CREATE_GOAL from "./../GraphQL/Mutations/Create_Goal/create-goal";
import "../App.css";

function IndividualGoal(props: any) {
  const [goalName, setGoalName] = useState("New Goal"); //useState(props.goalName or pull from database)
  const [goalIcon, setGoalIcon] = useState("");
  const [deadlineDate, setDeadlineDate] = useState("");
  const [deadlineTime, setDeadlineTime] = useState("");
  const [goalMission, setGoalMission] = useState("");
  const [shareText, setShareText] = useState("");
  const [readOnly, setReadOnly] = useState(true);
  const [showDropdown, setShowDropdown] = useState(false);
  const [emptyFieldError, setEmptyFieldError] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [addNewGoal] = useMutation(CREATE_GOAL);

  const handleAddNewGoal = (e: any) => {
    e.preventDefault();
    let fieldsToCheck = [goalName, goalIcon, goalMission, shareText];

    const checkForEmptyFields = (fields: string[]) =>
      fields.some((item: string) => !item);

    if (checkForEmptyFields(fieldsToCheck)) {
      console.log("Please check that all fields are filled before submitting!");
      setEmptyFieldError(true);
    } else {
      addNewGoal({
        variables: {
          input: {
            name: goalName,
            statement: goalMission,
            icon_file_location: goalIcon,
            share_text: shareText,
            deadline: `${deadlineDate} ${deadlineTime}`,
          },
        },
      })
        .then((res) => {
          if (!res.errors) {
            console.log("Goal successfully created!");
            setEmptyFieldError(false);
            setSuccessMessage(true);
            setGoalName("New Goal");
            setGoalIcon("");
            setDeadlineDate("");
            setDeadlineTime("");
            setGoalMission("");
            setShareText("");
          }
        })
        .catch((e) => {
          console.log(e.graphQLErrors, e.message);
          // GraphQL errors can be extracted here
          if (e.graphQLErrors) {
            if (e.graphQLErrors[0].errorType === "Unauthorized") {
              setErrorMessage(
                "You must be a CivAction Admin to perform this task!"
              );
            } else {
              // reduce to get message
              setErrorMessage(e.message);
            }
          }
        });
    }
  };

  // if (goalName === "New Goal") {
  //   setGoalName("");
  //   setReadOnly(false);
  // }

  if (goalName === "New Goal") {
    setGoalName("");
    setReadOnly(false);
  }

  function editableSmallBox() {
    if (readOnly) {
      return "smallInputBox";
    } else {
      return "editableSmallInputBox";
    }
  }

  function editableLargeBox() {
    if (readOnly) {
      return "largeInputBox";
    } else {
      return "editableLargeInputBox";
    }
  }

  function editableTimeBox() {
    if (readOnly) {
      return "timeInputBox";
    } else {
      return "editableTimeInputBox";
    }
  }

  function title() {
    return "New Goal";
  }

  return (
    <>
      <SmallNavbar type="Goal" name={goalName} index={0}>
        <div className="App">
          <header className="App-header">
            <h1 className="pageTitle">
              <FiIcons.FiTarget className="groupIcon" />
              {title()}
              <BsIcons.BsPencilFill
                className="editButton"
                onClick={() => setReadOnly(!readOnly)}
              />
              <BsIcons.BsThreeDots
                className="dotdotdotButton"
                onClick={() => setShowDropdown(!showDropdown)}
              />
              <div
                className={
                  showDropdown ? "active-dotDotDotArrow" : "dotDotDotArrow"
                }
              >
                <BsIcons.BsFillTriangleFill />
              </div>
              <div
                className={
                  showDropdown
                    ? "active-dotDotDotDropdown"
                    : "dotDotDotDropdown"
                }
              >
                <button className="dropDownOption">Archive Goal</button>

                <button className="dropDownOption">Download Analytics</button>
              </div>
            </h1>
            <div className="darkBoxTitle">
              Goal Name
              <div className="lightBoxTitle">45 character limit</div>
            </div>
            <input
              className={editableSmallBox()}
              readOnly={readOnly}
              type="text"
              maxLength={45}
              value={goalName}
              placeholder={"Enter goal name"}
              onChange={(e) => setGoalName(e.target.value)}
            />
            <div className="darkBoxTitle">
              Goal Icon
              {/* <div className="lightBoxTitle">.png or .avg --- 3mb limit</div> */}
            </div>
            {/* <input
              className={editableSmallBox()}
              readOnly={readOnly}
              type="file"
              value={goalIcon}
              placeholder={"No file chosen"}
              onChange={(e) => setGoalIcon(e.target.value)}
            /> */}
            <input
              className={editableSmallBox()}
              readOnly={readOnly}
              type="text"
              value={goalIcon}
              placeholder={"URL to Icon"}
              onChange={(e) => setGoalIcon(e.target.value)}
            />

            <div className="darkBoxTitle">
              Deadline
              <div className="lightBoxTitle">
                24 hours after the deadline, the action will be archived and no
                longer shown in the feed.
              </div>
            </div>
            <input
              className={editableTimeBox()}
              readOnly={readOnly}
              type="date"
              value={deadlineDate}
              onChange={(e) => setDeadlineDate(e.target.value)}
            />
            <input
              className={editableTimeBox()}
              readOnly={readOnly}
              type="time"
              value={deadlineTime}
              onChange={(e) => setDeadlineTime(e.target.value)}
            />
            <div className="darkBoxTitle">
              Short Description Text
              <div className="lightBoxTitle">1000 character limit</div>
            </div>
            <textarea
              className={editableLargeBox()}
              readOnly={readOnly}
              value={goalMission}
              maxLength={1000}
              placeholder={
                "Short description text of goal and why it matters here"
              }
              onChange={(e) => setGoalMission(e.target.value)}
            />
            <div className="darkBoxTitle">
              Share Text
              <div className="lightBoxTitle">1000 character limit</div>
            </div>
            <textarea
              className={editableLargeBox()}
              readOnly={readOnly}
              value={shareText}
              maxLength={1000}
              placeholder={
                "What text is used when this goal is shared on social media"
              }
              onChange={(e) => setShareText(e.target.value)}
            />
            <div className="createNewButtonContainer">
              {emptyFieldError && !errorMessage && (
                <div className="errorMessageContainerSmall">
                  <h3>ERROR!</h3>
                  Please make sure all fields are filled out before submitting!
                </div>
              )}
              {errorMessage && !successMessage && (
                <div className="errorMessageContainerSmall">
                  <h3>ERROR!</h3>
                  {errorMessage}
                </div>
              )}
              {successMessage && (
                <div className="successMessageContainerSmall">
                  <h3>SUCCESS!</h3>
                  Goal was created!
                </div>
              )}
              <button className="createNewButton" onClick={handleAddNewGoal}>
                Create New Goal
              </button>
            </div>
          </header>
        </div>
      </SmallNavbar>
    </>
  );
}

export default IndividualGoal;
