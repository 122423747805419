import * as React from 'react';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {StyledTableCell} from "../mui/StyledTableCell";
import StyledTableContainer from "../mui/StyledTableContainer";
import {StyledTableRow} from "../mui/StyledTableRow";
import {HeaderClass} from "../../models/class/HeaderClass";
import {GoalDTO} from "../../models/dto/GoalDTO";
import ApolloLazyQueryDialogContext from "../query/ApolloLazyQueryDialogContextWrapper";
import useModal from "../../hooks/useModal";
import GET_GOAL_BY_ID from "../../GraphQL/Queries/get-goal-by-id";
import {v4 as uuidv4} from "uuid";
import GoalDialogContent from "./GoalDialogContent";

export default ({goalDTO, headerClass}: {
    goalDTO: ReadonlyArray<GoalDTO>,
    headerClass: HeaderClass
}) => {
    const modalValues = useModal();
    return (
        <>
            <StyledTableContainer>
                <>
                    <TableHead>
                        <TableRow>
                            {headerClass.labels.map(headerLabel => (
                                <StyledTableCell align="left">{headerLabel}</StyledTableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {goalDTO.map((row) => (
                            <StyledTableRow style={{cursor: 'pointer'}} onClick={() => modalValues.handleModalId(String(row.id))} key={uuidv4()}>
                                <StyledTableCell align="left">{row.name}</StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </>
            </StyledTableContainer>
            <ApolloLazyQueryDialogContext
                modalValues={modalValues}
                documentNode={GET_GOAL_BY_ID}
            >
                <GoalDialogContent/>
            </ApolloLazyQueryDialogContext>
        </>
    );
}
