import {Amplify, Auth, Hub, Logger} from "aws-amplify";
import {useLocation, useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {CognitoUser} from "amazon-cognito-identity-js";

export default ():string | null => {
    
    const location = useLocation();
    const navigate = useNavigate();
    const [user, setUser] = useState<CognitoUser | null>(null);
    const [managerFullName, setManagerFullName] = useState<string | null>(null);
    const [managerEmail, setManagerEmail] = useState<string | null>(null);
    const [currentState, setCurrentState] = useState<string | null>(null);

    const handleAuthRouting = () => {
        Auth.currentAuthenticatedUser().then((user) => {
            console.log(user);
            if (!user) {
                navigate("/access_denied");
            } else {
                setUser(user);
            }
            const userGroups =
                Amplify.Auth.user.signInUserSession.idToken.payload["cognito:groups"];
    
            let isAuthorized = false;
            if(userGroups) isAuthorized = userGroups.some((group: string) =>
                group.includes("Manager") || group.includes("Admin")
            );
            if (!isAuthorized) {
                navigate("/access_denied");
            }
            if (isAuthorized && location.pathname === "/access_denied") navigate("/");
            if (!managerFullName) {
                switch (true) {
                    case user.username.includes("Google"):
                        setManagerFullName(user.signInUserSession.idToken.payload.name);
                        setManagerEmail(user.signInUserSession.idToken.payload.email);
                        break;
                    case user.username.includes("Facebook"):
                        setManagerFullName(user.signInUserSession.idToken.payload.name);
                        setManagerEmail(user.signInUserSession.idToken.payload.email);
                        break;
                    default:
                        setManagerFullName(
                            `${user.attributes.given_name} ${user.attributes.family_name}`
                        );
                        setManagerEmail(user.attributes.email);
                }
            }
        });
    };

    Hub.listen("auth", ({payload: {event, data}}) => {
        const logger = new Logger("My-Logger");
        switch (event) {
            case "signIn":
                logger.info("user signed in");
                break;
            case "signUp":
                logger.info("user signed up");
                break;
            case "signOut":
                logger.info("user signed out");
                setUser(null);
                setManagerFullName(null);
                break;
            case "signIn_failure":
                logger.error("user sign in failed");
                break;
            case "tokenRefresh":
                logger.info("token refresh succeeded");
                break;
            case "tokenRefresh_failure":
                setUser(null);
                Auth.signOut();
                logger.error("token refresh failed");
                break;
            case "configured":
                logger.info("the Auth module is configured");
        }
        if (event !== currentState || !currentState) {
            setCurrentState(event);

        }
        handleAuthRouting();
    });

    useEffect(() => {
        (async () => {
                await handleAuthRouting();
        })()
    }, []);
    return managerEmail;
}