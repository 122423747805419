import { gql } from "@apollo/client";

const CREATE_MILESTONE = gql`
  mutation MyMutation($input: CreateMilestoneInput!) {
    createMilestone(input: $input) {
      id
    }
  }
`;

export default CREATE_MILESTONE;

// {
//   "input": {
//     "title": "Milestone 2",
//     "description": "Milestone 2",
//     "goal": 16,
//     "milestone_type": 2,
//     "milestone_icon": "Milestone 2",
//     "deadline": "2022-07-30 12:00:00",
//     "target_number_of_completions": 100,
//     "precedent_milestone": [{"id": 3}],
//     "action_title": "Action 2",
//     "image_file_location": "Milestone 2",
//     "why_this_matters": "Milestone 2",
//     "primary_call_to_action_data": {"to_email_address": "michaeljamieson10@gmail.com", "subject_template": "This is a test subject line", "body_template": "This is some body text for the template"},
//     "secondary_call_to_action_data": {"phone_number": "+1 (805) 558 0815"},
//     "tertiary_call_to_action_data": {"video_url":  "video url cool", "embed_code": "embedjargonkewnflkewnf"},
//     "primary_call_to_action_type": "EMAIL",
//     "secondary_call_to_action_type": "PHONE",
//     "tertiary_call_to_action_type": "VIDEO",
//     "take_action": "Action 2",
//     "action_start_timestamp": "2022-07-29 12:00:00",
//     "share": "Action 2"
//   }
// }
