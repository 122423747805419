import React, { useState, useEffect } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import GET_GOAL_MILESTONES_BY_ID from "./../GraphQL/Queries/Goal_Milestones_By_id/goal-milestones-by-id";
import { useQuery } from "@apollo/client";

interface PropValues {
  goalId: number;
  handlePrecedentMilestone: (e: any, values: any) => void;
}

interface Milestones {
  id: number;
  key: number;
  title: string;
}

export default function Tags({ goalId, handlePrecedentMilestone }: any) {
  const [allGoalMilestones, setAllGoalMilestones] = useState<Milestones[]>([]);

  // GET LIST OF ALL MILESTONES OF THE CURRENTLY SELECTED GOAL
  const { data: get_goal_milestones, loading: is_loading } = useQuery(
    GET_GOAL_MILESTONES_BY_ID,
    {
      variables: {
        input: {
          id: goalId,
        },
      },
    }
  );

  useEffect(() => {
    if (get_goal_milestones && !is_loading)
      setAllGoalMilestones(get_goal_milestones?.GetGoalMilestonesById);
  }, [goalId, is_loading]);

  return (
    <Stack spacing={3} sx={{ width: 612 }}>
      <Autocomplete
        multiple
        id="tags-standard"
        options={allGoalMilestones}
        getOptionLabel={(option) => option.title}
        defaultValue={[]}
        onChange={handlePrecedentMilestone}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            label="Select Precedent Milestones"
            placeholder="Search Milestones"
          />
        )}
      />
    </Stack>
  );
}
