import React, {useState, useEffect, useContext} from "react";
import "./Topbar.css";
import * as BsIcons from "react-icons/bs";
import * as AiIcons from "react-icons/ai";
import {Link, useNavigate} from "react-router-dom";
import {UserClass} from "../models/class/UserClass";
import {UserContext} from "../context/UserContext";

function Topbar() {
  const [search, setSearch] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const user = useContext<UserClass>(UserContext);
  const navigate = useNavigate();
  const newGoal = {
    pathname: "/goals/createUpdate",
    name: "New Goal",
  };

  const newMilestoneType = {
    pathname: "/milestone_types/createUpdate",
    name: "New Milestone Type",
  };

  const newOrg = {
    pathname: "/organizations/createUpdate",
    name: "New Organization",
  };

  const newMilestone = {
    pathname: "/milestones/createUpdate",
    name: "New Milestone",
  };
  return (
    <div className="topbar">
      <Link to={"/" }style={{textDecoration:"none",color:"black"}}><h2 className="title">CivAdmin</h2></Link>
      <h3 className="subTitle">Admin Dashboard</h3>
      <div className="inputBoxDiv" /* Search Bar */>
        <AiIcons.AiOutlineSearch className="searchIcon" />
        <input
          className="inputBox"
          type="text"
          value={search}
          placeholder={"Search for Organizations, Goals, Milestones, etc."}
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>
      <div
        className="profileDiv" /* Profile Info - currently not linked to anything */
      >
        {/*<div className="profileName">{user ? user.fullName:"Loading"}</div>*/}
        <div className="profileName">{user.fullName}</div>
        <BsIcons.BsPersonCircle className="profileIcon" onClick={()=> navigate("/profile")} />
      </div>
      <div className="buttonDiv" /* Create Button */>
        <button
          className="createButton"
          onClick={() => setShowDropdown(!showDropdown)}
        >
          CREATE +
        </button>
        <div className={showDropdown ? "active-arrow" : "arrow"}>
          <BsIcons.BsFillTriangleFill />
        </div>
        <div
          className={
            showDropdown ? "active-dropdown" : "dropdown"
          } /* Create Button dropdown */
        >
          <Link to={newMilestone}>
            <button
              className="createDropdownOption"
              onClick={() => setShowDropdown(false)}
            >
              New Milestone
            </button>
          </Link>
          <Link to={newGoal}>
            <button
              className="createDropdownOption"
              onClick={() => setShowDropdown(false)}
            >
              New Goal
            </button>
          </Link>
          <Link to={newOrg}>
            <button
              className="createDropdownOption"
              onClick={() => setShowDropdown(false)}
            >
              New Organization
            </button>
          </Link>
          <Link to={newMilestoneType}>
            <button
              className="createDropdownOption"
              onClick={() => setShowDropdown(false)}
            >
              New Milestone Type
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Topbar;
