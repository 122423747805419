import { gql } from "@apollo/client";

const ALL_CALL_TO_ACTION = gql`
  query MyQuery {
    AllCallToActions {
      id
      name
    }
  }
`;

export default ALL_CALL_TO_ACTION;
