import * as React from 'react';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {StyledTableCell} from "../mui/StyledTableCell";
import StyledTableContainer from "../mui/StyledTableContainer";
import {StyledTableRow} from "../mui/StyledTableRow";
import {HeaderClass} from "../../models/class/HeaderClass";
import {MilestoneDTO} from "../../models/dto/MilestoneDTO";
import useModal from "../../hooks/useModal";
import { v4 as uuidv4 } from 'uuid';
import ApolloLazyQueryDialogContext from "../query/ApolloLazyQueryDialogContextWrapper";
import GET_MILESTONE_BY_ID from "../../GraphQL/get-milestone-by-id";
import MilestoneDialogContent from "./MilestoneDialogContent";

export default ({milestoneDTOs,headerClass}: {
    milestoneDTOs: ReadonlyArray<MilestoneDTO>,
    headerClass:HeaderClass
}) => {

    const modalValues = useModal();
    return (
        <>
        <StyledTableContainer>
            <>
                <TableHead>
                    <TableRow>
                        {headerClass.labels.map(headerLabel => (
                            <StyledTableCell align="left">{headerLabel}</StyledTableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {milestoneDTOs.map((row) => (
                        <StyledTableRow style={{cursor:'pointer'}} onClick={() => modalValues.handleModalId(row.id)} key={uuidv4()}>
                            <StyledTableCell align="left">{row.title}</StyledTableCell>
                        </StyledTableRow>
                    ))}
                </TableBody>
            </>
        </StyledTableContainer>
            <ApolloLazyQueryDialogContext
                modalValues={modalValues}
                documentNode={GET_MILESTONE_BY_ID}
            >
                <MilestoneDialogContent/>
            </ApolloLazyQueryDialogContext>
        </>
    );
}
