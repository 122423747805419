import React from "react";
import {Route, Routes} from "react-router-dom";
import MilestoneTypes from "../pages/MilestoneTypes";
import IndividualMilestoneTypeAnalytics from "../pages/IndividualMilestoneTypeAnalytics";
import IndividualMilestoneType from "../pages/IndividualMilestoneType";

export default () => {
    return (
        <Routes>
            <Route index element={<MilestoneTypes/>} />
            <Route path="createUpdate" element={<IndividualMilestoneType/>}/>
            <Route path="analytics" element={<IndividualMilestoneTypeAnalytics/>}/>
        </Routes>
    )
}