import Lottie from "lottie-react";
import errorOccurred from "./errorOccurred.json";
import {Card, CardHeader, Container, Grid, Typography} from "@mui/material";
import {ApolloError} from "@apollo/client";

export default ({error}:{error:ApolloError | undefined}) => {
    if(error == undefined) return null;

    return (
        <Grid container style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh'}}>
            <Container maxWidth="lg">
                <Card
                    raised
                    sx={{
                        padding: "0.1em",
                    }}
                >
                    <Grid container style={{flexDirection: "column", alignItems: 'center',}}>
                        <Grid item>
                            <Lottie style={{
                                height: 280,
                                width: 280
                            }} animationData={errorOccurred} loop={true}/>
                        </Grid>
                        <Grid item style={{marginTop:10,marginBottom:10,alignItems:"center",textAlign:"center"}}>
                            <Typography variant={"h5"} gutterBottom> Error Message</Typography>
                            <Typography variant={"body1"} gutterBottom> {error.message}</Typography>
                            {error.clientErrors.length !==0 && <Typography variant={"h5"} gutterBottom>Client Error Messages</Typography>}
                            {error.clientErrors.map(e => <Typography variant={"body1"} gutterBottom>{e.message}</Typography>)}
                            {error.graphQLErrors.length !==0 && <Typography variant={"h5"} gutterBottom>graphQL Error Messages</Typography>}
                            { error.graphQLErrors.map(e => <Typography variant={"body1"} gutterBottom>{e.message}</Typography>)}
                            {error.networkError && <Typography variant={"h5"} gutterBottom>Network Error Messages</Typography>}
                            <Typography variant={"body1"} gutterBottom>{error.networkError}</Typography>
                        </Grid>
                    </Grid>
                </Card>
            </Container>
        </Grid>
    )
}