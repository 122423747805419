import React, {useContext} from "react";
import {UserClass} from "../models/class/UserClass";
import {UserContext} from "../context/UserContext";
import {Route, Routes} from "react-router-dom";
import Goals from "../pages/Goals";
import IndividualGoalAnalytics from "../pages/IndividualGoalAnalytics";
import IndividualGoal from "../pages/IndividualGoal";

export default () => {
    const user = useContext<UserClass>(UserContext);
    return (
        <Routes>
            <Route index element={<Goals/>} />
            <Route path="createUpdate" element={<IndividualGoal managerEmail={user.email} />}/>
            <Route path="analytics" element={<IndividualGoalAnalytics/>}/>
        </Routes>
    )
}