import { gql } from "@apollo/client";

const CREATE_GOAL = gql`
  mutation MyMutation($input: CreateGoalInput!) {
    createGoal(input: $input) {
      name
    }
  }
`;

export default CREATE_GOAL;

// {
//   "input": {
//     "name": <STRING>,
//     "deadline": <STRING>, #format 'YYYY-MM-DD 00:00:00' Time optional defaults to 00:00:00
//     "statement": <STRING>,
//     "manager": 1,
//     "icon_file_location": <STRING>,
//     "share_text": <STRING>
//   }
// }