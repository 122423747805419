import { gql } from "@apollo/client";

const GET_USER_PROFILE_DATA = gql`
  query MyQuery {
    GetLoggedInUser {
      id
      first_name
      last_name
      email
      date_of_birth
      phone_number
      referral_code
    }
  }
`;

export default GET_USER_PROFILE_DATA;

// EX:

// {
//   "input": {
//     "referral_code": "american-red-cross"
//   }
// }
