import * as React from 'react';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {OrganizationDTO} from "../../models/dto/OrganizationDTO";
import {StyledTableCell} from "../mui/StyledTableCell";
import StyledTableContainer from "../mui/StyledTableContainer";
import {StyledTableRow} from "../mui/StyledTableRow";
import {HeaderClass} from "../../models/class/HeaderClass";
import useModal from "../../hooks/useModal";
import {v4 as uuidv4} from "uuid";
import ApolloLazyQueryDialogContext from "../query/ApolloLazyQueryDialogContextWrapper";
import GET_ORGANIZATION_DATA from "../../GraphQL/Queries/organization/get-organization-data";
import OrganizationDialogContent from "./OrganizationDialogContent";

export default ({organizationsDTO, headerClass}: {
    organizationsDTO: ReadonlyArray<OrganizationDTO>,
    headerClass: HeaderClass
}) => {
    const modalValues = useModal();
    return (
        <>
            <StyledTableContainer>
                <>
                    <TableHead>
                        <TableRow>
                            {headerClass.labels.map(headerLabel => (
                                <StyledTableCell align="left">{headerLabel}</StyledTableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {organizationsDTO.map((row) => (
                            <StyledTableRow style={{cursor: 'pointer'}}
                                            onClick={() => modalValues.handleModalId(String(row.id))} key={uuidv4()}>
                                <StyledTableCell align="left">{row.name}</StyledTableCell>
                                <StyledTableCell align="left">{row.mission_statement}</StyledTableCell>
                                <StyledTableCell align="left">{row.share_text}</StyledTableCell>
                                <StyledTableCell align="left">{row.motto}</StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </>
            </StyledTableContainer>
            <ApolloLazyQueryDialogContext
                modalValues={modalValues}
                documentNode={GET_ORGANIZATION_DATA}
            >
                <OrganizationDialogContent/>
            </ApolloLazyQueryDialogContext>
        </>
    );
}
