import React from "react";
import "./App.css";
import {Amplify} from "aws-amplify";
import {Authenticator} from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import config from "./util/AWS-Exports";
import useOnAppLoadout from "./hooks/useOnAppLoadout";
import ApplicationRoutes from "./route/ApplicationRoutes";
import ApolloWrapper from "./components/activity_indicator/ApolloWrapper";

Amplify.configure(config);

function App() {

    const {
        userData,
        apolloActivityResponse
    } = useOnAppLoadout();

//made it here
    return (
        <Authenticator
            loginMechanisms={["email"]}
            signUpAttributes={[
                "given_name",
                "family_name",
                "preferred_username",
                "phone_number",
            ]}
            socialProviders={["facebook", "google"]}
        >
            {({signOut}) => (
                <ApolloWrapper apolloActivityResponse={apolloActivityResponse}>
                    <>
                    {/*    */}
                    <ApplicationRoutes
                        userDTO={userData}
                    />
                    </>
                </ApolloWrapper>
            )}
        </Authenticator>
    );
}

export default App;
