import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./SmallNavbar.css";

function Navbar(props: any) {
  //need to pass in type (goals, milestones, orgs), name of it, and index or some other refrence to pull data based off of
  const [currentScreen, setcurrentScreen] = useState(props.index);

  function settings(index: number) {
    if (currentScreen === index) {
      return "nav-text-selected";
    } else {
      return "nav-text";
    }
  }

  const overview = {
    pathname: "/" + props.type,
    name: props.name,
  };

  const analytics = {
    pathname: "/" + props.type + "/analytics",
    name: props.name,
  };

  return (
    <>
      <nav className="nav-menu">
        <ul className="nav-menu-items">
          <>
            <div className="typeOfBar">{props.type}</div>
            <div className="specificName">{props.name}</div>
            <Link to={"/" + props.type + "s"}>
              <p className="goBack">Back to {props.type}s</p>
            </Link>
            <Link
              to={overview}
              key={1}
              className={settings(0)}
              onClick={() => setcurrentScreen(0)}
            >
              <span>Overview</span>
            </Link>
            <Link
              to={analytics}
              key={1}
              className={settings(1)}
              onClick={() => setcurrentScreen(1)}
            >
              <span>Analytics</span>
            </Link>
          </>
        </ul>
      </nav>
      <>{props.children}</>
    </>
  );
}

export default Navbar;
