import React, { useState, useEffect } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ClearIcon from "@mui/icons-material/Clear";
import AddIcon from "@mui/icons-material/Add";
import "./ActionType.css";

interface PropValues {
  callToActionIdentifier?: string;
  primaryValues?: {
    itemList: string[];
    cta_type: "PREPARE";
  };
  onValueChange?: (name: string, value: any) => any
}

interface PrepareInterface {
  itemList: string[];
}
function ActionTypePrepare({
  values,
  onValueChange
}: any) {
  const [addItemValue, setAddItemValue] = useState("");

  const handlePrepareInput = (value: string, adding: boolean) => {
    if (adding) {
      onValueChange("itemList", values?.itemList ? values.itemList.push(value) : [value])
      setAddItemValue("");
    } else {
      onValueChange("itemList",values.itemList.filter((item:string) => item !== value));
    }
  };
  return (
    <div className="textContainer">
      <div className="darkBoxTitle">
        Add Item
        <div className="lightBoxTitle"></div>
      </div>
      <div className="addListItemContainer">
        <input
          className="editableSmallInputBoxCTA"
          type="text"
          value={addItemValue}
          onChange={(e) => setAddItemValue(e.target.value)}
          placeholder={"Enter item name"}
        />
        <div className="addListItem">
          <AddIcon onClick={() => handlePrepareInput(addItemValue, true)} />
        </div>
      </div>
      <div className="darkBoxTitle">
        Item List
        <div className="lightBoxTitle"></div>
      </div>
      <div className="prepareList">
        {values?.itemList?.map((item: string, i:number) => (
          <div className="listItemContainer">
            <div className="listItem">
              {i + 1}. {item}
            </div>
            <div className="removeListItem">
              <ClearIcon onClick={() => handlePrepareInput(item, false)} />
            </div>
          </div>
        ))}
      </div>
      {values?.itemList?.length > 3 && (
        <span className="scrollDownArrow">
          <ArrowDropDownIcon />
        </span>
      )}
    </div>
  );
}

export default ActionTypePrepare;
