import {useNavigate} from "react-router-dom";

export default () => {
    const navigate = useNavigate();
    const handleLogout = () => {
        localStorage.clear();
        navigate("/");
        window.location.reload();
        // toast.success("You Successfully Have Logged Out");
    }
    return {
        handleLogout
    };
}