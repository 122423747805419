import { gql } from "@apollo/client";

const GET_ORGANIZATION_DATA = gql`
  query OrganizationDetailQuery($input: GetOrganizationInput!) {
    GetOrganization(input: $input) {
      id
      name
      motto
      mission_statement
      get_involved_statement
      share_text
      thumbnail_image_file_location
      website_url
    }
  }
`;

export default GET_ORGANIZATION_DATA;

// EX:

// {
//   "input": {
//     "id": 1
//   }
// }
