import React from "react";
import ReactDOM from "react-dom";
import {BrowserRouter} from "react-router-dom";
import {ApolloClient, InMemoryCache, ApolloProvider, ApolloLink, HttpLink} from "@apollo/client";
import { onError} from "@apollo/client/link/error";
import config from "./util/AWS-Exports";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {CssBaseline, ThemeProvider} from "@mui/material";
import muiTheme from "./theme/muiTheme";
import { Auth} from "aws-amplify";
import {setContext} from "@apollo/client/link/context";

const setAuthorizationLink = setContext((request, prevContext) => {
    return Auth.currentSession().then(userSession => {
        const token = userSession ? userSession.getIdToken().getJwtToken() : "";
        return _buildAuthHeader(token);
    });
})

const unauthenticatedErrorLink = onError(
    ({networkError}) => {
        if(networkError && networkError.name === 'ServerError' && networkError.message.includes('401')) {
            console.debug("NEED TO HANDLE UNAUTHENTICATED");
        }
    }
)

const _buildAuthHeader = (token : String) => {
    return {
        headers: {
            Authorization: `${token}`
        }
    }
}

const client = new ApolloClient({
    link: ApolloLink.from([
        unauthenticatedErrorLink,
        setAuthorizationLink,
        new HttpLink({uri: config.aws_appsync_graphqlEndpoint})
    ]),
    cache: new InMemoryCache(),
});


ReactDOM.render(
    <ApolloProvider client={client}>
        <BrowserRouter>
            <React.StrictMode>
                <ThemeProvider theme={muiTheme}>
                    <CssBaseline>
                    <App/>
                    </CssBaseline>
                </ThemeProvider>
            </React.StrictMode>
        </BrowserRouter>
        <ToastContainer
            autoClose={false}
            closeOnClick
        />
    </ApolloProvider>,
    document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
