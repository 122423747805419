import Lottie from "lottie-react";
import loadingAnimation from "../../lottie/loading.json";
import {Card, CardHeader, Container, Grid, Typography} from "@mui/material";

export default () => {
    return (
        <Grid container style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh'}}>
            <Container maxWidth="lg">

                    <Grid container style={{flexDirection: "column", alignItems: 'center',}}>
                        <Grid item>
                            <Lottie style={{
                                height: 280,
                                width: 280
                            }} animationData={loadingAnimation} loop={true}/>
                        </Grid>
                        <Grid item style={{marginBottom:10}}>
                            <Typography variant={"h6"} gutterBottom>
                                If loading takes too long, please contact customer support or refresh your browser.
                            </Typography>
                        </Grid>
                    </Grid>
            </Container>
        </Grid>
    )
}