function AccessDeniedPage() {
  function refreshPage() {
    window.localStorage.clear();
    window.location.reload();
  }

  return (
    <div className="app">
      <div className="accessDeniedPage">
        <div className="accessDeniedContainer">
          <h1 className="accessDeniedH1">403</h1>
          <h3 className="accessDeniedH3">Access Denied</h3>
          <hr className="accessDeniedHR"></hr>
          <span>The page you're trying to view has restricted access.</span>
          <span>
            Please make sure that your email and password are correct.
          </span>
          <br></br>
          <button onClick={refreshPage} className="accessDeniedButton">
            Back to Login
          </button>
        </div>
      </div>
    </div>
  );
}

export default AccessDeniedPage;
